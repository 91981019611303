
import { Mixins, Component, Watch, Ref } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import { Form } from "element-ui/types";
import ETable from '@/components/ETable.vue';
import { cloneDeep } from "lodash";

import { getSymptomList, editSymptom } from "@/api/symptomManage";
import RelateDetection from "./components/relateDetection.vue";

@Component({ components: { ETable, RelateDetection } })
export default class symptomManage extends Mixins(tablePagination, loading, Resize) {
    @Ref("validateForm") validateForm: Form;

    diagnosesTypeOptions = [
        { label: "智能脉诊", value: "10003" },
        { label: "智能舌诊", value: "10001" },
        { label: "体质辨识", value: "10004" },
        { label: "肺功能评估", value: "10005" },
        { label: "智能辨证", value: "10007" },
    ]

    queryForm = {
        name: "",
        presName: "",
        diagnosesId: "",
    }

    columns = [
        { prop: "id", label: "ID" },
        { prop: "name", label: "症状名称" },
        { prop: "symptomDesc", label: "症状描述", minWidth: "150" },
        { prop: "presNameList", label: "检测结果", minWidth: "150" },
        { prop: "modifiedBy", label: "操作人" },
        { prop: "modifiedTime", label: "最后操作时间" },
        { prop: "action", label: "操作" },
    ];

    tableData = [];

    dialogTitle = "";
    visible = false;

    formData = {
        name: "",
        symptomDesc: "",
    };

    formRules = {
        name: [{ required: true, message: "请输入症状名称", trigger: "blur" }],
    }

    relateDialogVisible = false;
    stashResultList = [];
    nurseId = "";

    mounted() {
        this.getData();
        this.windowResize();
    }

    getData() {
        this.showLoading();
        const params = {
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            ...this.queryForm
        }
        getSymptomList(params).then((res) => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    resetPages() {
        this.currentPage = 1;
    }

    handleSearch() {
        this.resetPages();
        this.getData();
    }

    handleEdit(row) {
        this.dialogTitle = row.id ? "编辑" : "新增";
        this.formData = cloneDeep(row);
        this.visible = true;
    }

    handleRelate(row) {
        this.stashResultList = [];
        this.nurseId = row.id;
        for (const type of this.diagnosesTypeOptions) {
            this.stashResultList.push(
                row.presNameList.filter(item => item.libCode == type.value).map(el => el.id)
            );
        }
        this.stashResultList[4] = row.intelligentSymptomList?.map((el) => el.id)
        this.relateDialogVisible = true;
    }

    dialogClose() {
        this.validateForm?.resetFields();
        this.visible = false;
    }

    saveAppUpdate() {
        this.validateForm?.validate().then((valid) => {
            if (valid) {
                const params = this.formData;
                this.showLoading();
                editSymptom(params).then((res) => {
                    this.$message.success("保存成功");
                    this.dialogClose();
                    this.getData();
                }).finally(() => {
                    this.hideLoading();
                })
            }
        })
    }
}
