
import {
    Watch,
    Mixins,
    Component,
    Ref
} from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";
import DialecticForm from "./components/dialecticForm.vue";

import { getIntelligentType, getIntelligentList } from "@/api/dialectic";

import { GenderEnum2 } from "@/enum/symptoms.enum";

@Component({
    components: {
        ETable, DialecticForm
    }
})
export default class DialecticList extends Mixins(loading, tablePagination, Resize) {

    queryForm = {
        symptomName: "",
        symptomType: "",
        symptomStatus: "",
    }

    columns = [
        {
            label: "名称",
            prop: "symptomName",
            minWidth: 150,
            align: "center"
        },
        {
            label: "适用人群",
            prop: "",
            minWidth: 100,
            align: "center",
            formatter: (row) => {
                return GenderEnum2[row.gender];
            }
        },
        {
            label: "状态",
            prop: "symptomStatus",
            width: 150,
            align: "center"
        },
        {
            label: "操作人",
            prop: "modifiedBy",
            width: 150,
            align: "center"
        },
        {
            label: "最后操作时间",
            prop: "modifiedTime",
            minWidth: 180,
            align: "center"
        },
        {
            label: "操作",
            prop: "action",
            fixed: "right",
            width: 140,
            align: "center"
        },
    ]
    tableData = [{
    }];

    dialogVisible = false;
    dialogForm = {
        id: "",
        symptomName: "",
        symptomStatus: false,
        symptomType: "",
        gender: 0
    };

    symptomTypeOptions = [];


    mounted(){
        this.windowResize();
        this.getIntelligentType();
        this.getData();
    }

    getData() {
        this.showLoading();
        getIntelligentList({
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }).then(res => {
            this.hideLoading();
            // res.data.list.forEach((el) => {
            //     console.log(el.symptomList?.map(s => s.name))
            //     el.symptomListDesc = el.symptomList?.map(s => s.name).join("、");
            // })
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }

    handleEdit(row = {} as any) {
        this.dialogVisible = true;
        if (row.id) {
            this.$nextTick(() => {
                Object.assign(this.dialogForm, { ...row, symptomStatus: Boolean(row.symptomStatus) });
            })
        } else {
            this.dialogForm = { id: "",symptomName: "", symptomStatus: true, symptomType: "", gender: 0 }
        }
    }

    getIntelligentType() {
        getIntelligentType().then(res => {
            this.symptomTypeOptions = res.data;
        })
    }

}
