
import {
    updateNursePresBaseInfo
} from "@/api/nurse";
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    ProvideReactive,
    Inject,
    InjectReactive
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";
import NursePresAddAj from "./components/nursePresAddAj.vue"
import NursePresAddXz from "./components/nursePresAddXz.vue"
import NursePresAddJm from "./components/nursePresAddJm.vue"
import NursePresAddWt from "./components/nursePresAddWt.vue"
import NursePresAddWy from "./components/nursePresAddWy.vue"
import {
    ElForm
} from "element-ui/types/form";

import {
    nursePresTermList,
    getPresOne
} from "@/api/nurse";

import { IHomeInstance } from "@/types/global";

import { cloneDeep } from "lodash";

@Component({
    components: {
        UploadImg,
        NursePresAddAj,
        NursePresAddXz,
        NursePresAddJm,
        NursePresAddWt,
        NursePresAddWy
    },
    inject: ["HomeInstance"],
    // injectReactive: ["HomeInstance"]
})
export default class PresAdd extends Vue {
    @Ref("validateFormRef") validateFormRef!: ElForm;
    @Ref("NursePresAddAj") NursePresAddAj!: NursePresAddAj;
    @Ref("NursePresAddXz") NursePresAddXz!: NursePresAddXz;
    @Ref("NursePresAddJm") NursePresAddJm!: NursePresAddJm;
    @Ref("NursePresAddWy") NursePresAddWy!: NursePresAddWy;
    @Ref("NursePresAddWt") NursePresAddWt!: NursePresAddWt;

    @InjectReactive() HomeInstance !: IHomeInstance;

    @ProvideReactive("addFormData")
    formData = {
        id: "",
        presType: 1,
        healthType: 1,
        presName: "",
        imgUrl: [],
        suitableFor: "",
        termsId: [],
        duration: undefined,
        status: true,
        instruction: "",
        effect: "",
        period: "",
        solution: ""
    }

    rules = {
        healthType: {
            required: true,
            message: "请选择治疗类型",
            trigger: ['blur','change']
        },
        presName: {
            required: true,
            message: "请输入处方名称",
            trigger: ['blur','change']
        },
        imgUrl: {
            required: true,
            type: "array",
            message: "请上传处方图",
            trigger: ['blur','change']
        },
        suitableFor: {
            required: true,
            message: "请选择适用人群",
            trigger: ['blur','change']
        },
        instruction: {
            required: true,
            message: "请输入介绍说明",
            trigger: ['blur','change']
        },
        effect: {
            required: true,
            message: "请输入功效",
            trigger: ['blur','change']
        },
        period: {
            required: true,
            message: "请输入调养周期",
            trigger: ['blur','change']
        },
        solution: {
            required: true,
            message: "请输入方法",
            trigger: ['blur','change']
        },
    }

    healthTypeOptions = [{
        label: "艾灸",
        value: 1
    },
    {
        label: "筋膜松解",
        value: 2
    },
    {
        label: "中药熏蒸",
        value: 3
    },
    {
        label: "温阳调养",
        value: 4
    },
    {
        label: "温通调养",
        value: 5
    },
    ]

    syjqOptions = [{
        label: "艾灸",
        value: 1
    },
    {
        label: "筋膜松解",
        value: 2
    },
    ]

    suitableForOptions = [{
        label: "通用",
        value: 0
    },
    {
        label: "男",
        value: 1
    },
    {
        label: "女",
        value: 2
    },
    ]

    termsOptions = [];

    saveLoading = false;

    created() {
        this.formData.id = this.$route.query.id as string;
    }

    mounted() {
        this.getPresOne();
        this.nursePresTermList();
    }

    getPresOne() {
        if (!this.formData.id) return;
        getPresOne(this.formData.id).then((res) => {
            Object.assign(this.formData,{
                ...res.data,
                termsId: res.data.termsList?.map((el) => el.id),
                status: Boolean(res.data.status),
                imgUrl: [{
                    url: res.data.imgUrl
                }]
            });
        })
    }

    getTermsId() {
        const { formData,termsOptions } = this;
        console.log(formData);
        if (formData.termsId && termsOptions.length) {
            formData.termsId = termsOptions?.filter((el) => formData.termsId.includes(el.id))
            console.log("formData.termsId",formData.termsId);
        }
    }

    nursePresTermList() {
        nursePresTermList().then(res => {
            this.termsOptions = res.data || [];

        })
    }

    dialogClose() {
        this.HomeInstance.tabRemove("nurse-pres-add");
    }

    dialogConfirm() {
        this.validateFormRef?.validate().then(() => {
            console.log("提交表单")

            const params = {
                ...this.formData,
                imgUrl: this.formData.imgUrl?.[0]?.url,
                status: Number(this.formData.status),
            }
            
            updateNursePresBaseInfo(params).then((res) => {
                this.formData.id = res.data;
                const pro = this.NursePresAddAj?.validateForm || this.NursePresAddXz?.validateForm || this.NursePresAddJm?.validateForm ||
                    this.NursePresAddWy?.validateForm || this.NursePresAddWt?.validateForm

                // @ts-ignore
                pro()?.then(() => {
                    this.$message.success("操作成功");
                    this.HomeInstance.tabRemove("nurse-pres-add");
                })
                // this.NursePresAddAj?.validateForm().then((res) => {
                //     this.HomeInstance.tabRemove("nurse-pres-add");
                // })
                // this.NursePresAddXz?.validateForm().then((res) => {
                //     this.HomeInstance.tabRemove("nurse-pres-add");
                // })
                // this.NursePresAddJm?.validateForm().then((res) => {
                //     this.HomeInstance.tabRemove("nurse-pres-add");
                // })
                // this.NursePresAddWy?.validateForm().then((res) => {
                //     this.HomeInstance.tabRemove("nurse-pres-add");
                // })
                // this.NursePresAddWt?.validateForm().then((res) => {
                //     this.HomeInstance.tabRemove("nurse-pres-add");
                // })
            })
        })


    }
}
