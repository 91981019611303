
import { Watch, Component, Vue, Ref, PropSync, Prop } from 'vue-property-decorator';
import { getStoreListApi } from "@/api/store";

@Component
export default class selectStore extends Vue {
    @PropSync("visible") showTransfer !: boolean;
    @Prop({ type: Array, default: () => [] }) stashList !: Array<any>;
    @Prop({ default: true }) clearStashListFlag !: boolean;
    @Ref("transferRef") transferRef: any;

    stashStoreList = [];
    storeList = [];

    mounted() {
        this.getStoreList();
    }
    getStoreList() {
        getStoreListApi({ pageNum: 1, pageSize: 1000, merchantId: this.$route.query.merchantId }).then((res) => {
            this.storeList = res.data.list;
        });
    };

    closeTransfer() {
        this.transferRef?.clearQuery("left");
        this.transferRef?.clearQuery("right");
        if (this.clearStashListFlag) this.clearStashList();
        this.showTransfer = false;
    };

    clearStashList() {
        this.stashStoreList = [];
    };

    getStashStoreInfoList() {
        return this.stashStoreList.map((item) => {
            const { id, storeName } = this.storeList.find((store) => store.id == item) || {};
            return {
                id,
                storeName
            };
        });
    };

    confirmTransfer() {
        this.$emit("syncStoreList", this.stashStoreList);
        this.$emit("syncStoreInfoList", this.getStashStoreInfoList());
        this.closeTransfer();
    };

    @Watch("stashList")
    handleStashList(list) {
        this.stashStoreList = [...list];
    }

    // @Watch("showTransfer")
    // handleShowTransfer(val) {
    //     if (this.showTransfer) {
    //         this.getStoreList();
    //     }
    // }

    // defineExpose({
    //     clearStashList
    // });
}
