
import { Mixins, Component, Prop, Watch } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import { getUserAuthorityInfo } from "@/api/admin";

import ETable from '@/components/ETable.vue';

const initData = [
    {
      label: "门店",
      columns: [
        { prop: "authority", label: "门店角色", width: "200px" },
        { prop: "name", label: "门店名称" }
      ],
      tableData: [
        { authority: "门店管理员", name: "" },
        { authority: "店员", name: "" },
        { authority: "督导", name: "" },
        { authority: "服务跟进", name: "" }
      ]
    },
    {
      label: "服务商",
      columns: [
        { prop: "authority", label: "服务商角色", width: "200px" },
        { prop: "name", label: "服务商名称" }
      ],
      tableData: [
        { authority: "服务商负责人", name: "" }
      ]
    },
    {
      label: "品牌方",
      columns: [
        { prop: "authority", label: "品牌方角色", width: "200px" },
        { prop: "name", label: "品牌方名称" }
      ],
      tableData: [
        { authority: "品牌方负责人", name: "" }
      ]
    }
  ];

@Component({
  components: { ETable }
})
export default class AuthorityInfo extends Mixins(tablePagination, loading, Resize) {
  @Prop([Number]) private id!: Number;
  @Prop([Boolean]) private dialogVisible!: boolean;

  data = [
    {
      label: "门店",
      columns: [
        { prop: "authority", label: "门店角色", width: "200px" },
        { prop: "name", label: "门店名称" }
      ],
      tableData: [
        { authority: "门店管理员", name: "" },
        { authority: "店员", name: "" },
        { authority: "督导", name: "" },
        { authority: "服务跟进", name: "" }
      ]
    },
    {
      label: "服务商",
      columns: [
        { prop: "authority", label: "服务商角色", width: "200px" },
        { prop: "name", label: "服务商名称" }
      ],
      tableData: [
        { authority: "服务商负责人", name: "" }
      ]
    },
    {
      label: "品牌方",
      columns: [
        { prop: "authority", label: "品牌方角色", width: "200px" },
        { prop: "name", label: "品牌方名称" }
      ],
      tableData: [
        { authority: "品牌方负责人", name: "" }
      ]
    }
  ];

  mounted() {
    this.windowResize();
  }

  @Watch("dialogVisible", { immediate: true })
  getAuthorityInfo() {
    if(!this.dialogVisible) return;
    getUserAuthorityInfo(this.id).then((res) => {
      this.data[0].tableData[0].name = res.data?.ownerStoreNames.join("、");
      this.data[0].tableData[1].name = res.data?.staffStoreNames.join("、");
      this.data[0].tableData[2].name = res.data?.mentorStoreNames.join("、");
      this.data[0].tableData[3].name = res.data?.supportStoreNames.join("、");
      this.data[1].tableData[0].name = res.data?.serviceStoreNames.join("、");
      this.data[2].tableData[0].name = res.data?.merchantStoreNames.join("、");
    });
  }

  beforeClose() {
    this.$nextTick(() => {
      Object.assign(this.data, initData);
    })
    this.$emit("close");
  }
}
