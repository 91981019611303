
import {
    Watch,
    Mixins,
    Component,
    Vue,
    InjectReactive
} from 'vue-property-decorator'
import Resize from "@/mixins/resize";
import tablePagination from "@/mixins/tablePagination";
import Loading from "@/mixins/loading";

import ETable from "@/components/ETable.vue";

import { nursePresWayList } from "@/api/nurse";

import { formatTextarea } from "@/utils/common";

import SelectProduct from "../../banner/components/selectProduct.vue";

import { updateNursePresBaseInfo, copyNursePres } from "@/api/nurse";

@Component({
    components: {
        ETable, SelectProduct
    }
})
export default class NursePresHome extends Mixins(Loading, tablePagination, Resize) {
    @InjectReactive() currentTab;

    formatTextarea = formatTextarea

    queryForm = {
        name: "",
        presType: 2,
        custom: ""
    };

    columns = [{
            label: "ID",
            prop: "id",
            width: 70,
            align: "center"
        },
        {
            label: "处方名称",
            prop: "presName",
            minWidth: 150,
            align: "center"
        },
        {
            label: "适用人群",
            prop: "usageFor",
            width: 120,
            align: "center"
        },
        {
            label: "用量",
            prop: "dosage",
            width: 100,
            align: "center"
        },
        {
            label: "配方",
            prop: "formula",
            minWidth: 180,
            align: "center"
        },

        {
            label: "功效",
            prop: "effect",
            minWidth: 200,
            align: "center"
        },

        {
            label: "关联商品",
            prop: "productName",
            minWidth: 180,
            align: "center"
        },

        {
            label: "状态",
            prop: "status",
            minWidth: 180,
            align: "center"
        },

        {
            label: "操作人",
            prop: "modifiedBy",
            minWidth: 150,
            align: "center"
        },
        {
            label: "最后操作时间",
            prop: "modifiedTime",
            minWidth: 180,
            align: "center"
        },
        {
            label: "操作",
            prop: "action",
            fixed: "right",
            width: 170,
            align: "center"
        },
    ]
    tableData = [{
    }];

    relatedDialogVisible = false;

    presInfo = {
        id: ""
    };

    mounted() {
        this.windowResize();
        this.getData();
    }

    getData() {
        this.showLoading();
        nursePresWayList({
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }).then(res => {
            this.hideLoading();
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }

    add() { 
        this.$router.push({
            path: "/nurse-pres-add-home"
        })
    }

    edit(row) {
        this.$router.push({
            path: "/nurse-pres-add-home",
            query: { id: row.id }
        })
    }

    copy(row) {
        const { id } = row;
        copyNursePres(id).then(() => {
            this.$message.success("操作成功");
            this.getData();
        })
    }

    relationSpu(row) {
        this.presInfo = {...row};
        this.relatedDialogVisible = true;
    }

    syncProductList(spu) {
        const params = {
            ...this.presInfo,
            spu,
        }
        updateNursePresBaseInfo(params).then(() => {
            this.$message.success("操作成功");
            this.getData();
        })

    }

    @Watch("currentTab", { immediate: true })
    currentTabChange() {
        if(this.currentTab == "1") {
            this.windowResize();
        }   
    }
}
