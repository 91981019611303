
import { Mixins, Component, Watch, Ref } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";
import ETable from "@/components/ETable.vue";
import { Form } from "element-ui/types";
import loading from "@/mixins/loading";

import { appUpdateList, appUpdateDelete } from '@/api/appUpdate';

@Component({
    components: { ETable }
})
export default class appUpdate extends Mixins(tablePagination, loading, Resize) {
    @Ref("validateForm") validateForm: Form;

    queryForm = {
        versionNumber: "",
    };

    columns = [
        { prop: "versionNumber", label: "版本号" },
        { prop: "imgUrl", label: "图" },
        { prop: "updateDescription", label: "更新说明" },
        { prop: "status", label: "状态"},
        { prop: "modifiedBy", label: "操作人" },
        { prop: "modifiedTime", label: "最后操作时间" },
        { prop: "action", label: "操作" },
    ];

    tableData = [];
    itemTotal = 0;

    mounted() {
        this.getData();
        this.windowResize(250)
    }

    getData() {
        this.showLoading();
        appUpdateList({
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            ...this.queryForm
        }).then(res => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    resetPages() {
        this.currentPage = 1;
    }

    handleSearch() {
        this.resetPages();
        this.getData();
    }

    handleEdit(row) {
        this.$router.push({
            path: "/appUpdateEdit",
            query: { id: row.id }
        })
    }

    handleDelete(row) {
        this.$confirm(`删除后无法恢复，确认删除？`, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
        }).then(() => {
            const params = { id: row.id }
            this.showLoading();
            appUpdateDelete(params).then((res) => {
                this.$message.success("删除成功");
                this.getData();
            }).finally(() => {
                this.hideLoading();
            })
        })
    }
    getStatusText(status: number): string {
        return status === 0 ? '禁用' : '启用';
    }
}
