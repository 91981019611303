export enum PresMusic {
    "",
    "火音缓解焦虑",
    "碧涧流泉，洗涤心尘",
    "妙音疗心",
    "疏肝养心清肺",
    "养心疏肝",
    "静心安神",
    "水火相济",
    "普安咒",
    "养心安神，消除疲劳",
    "养心安神，安稳身心",
    "养心安神，静心去燥",
    "徵音养心，养阳助心",
    "古琴音药，商音养肺",
    "肺之云凝，商调药音疗",
    "属金商音，平衡肺气",
    "五音疗疾，肺肾互养",
    "金音养肺",
    "净化排毒",
    "润肺健脾养心",
    "金音养肺，平衡肺气",
    "疏肝养肺，平衡气血",
    "提高免疫力，养肺护肺",
    "商音养肺",
    "养心安神",
    "角调疏肝",
    '木音养肺，平衡肺气',
    '木音疏肝，金音清肺',
    "木音疏肝，羽调养肾",
    "疏肝补肾，养心安神",
    "疏肝畅气，养心安神",
    "土音养脾，楚歌",
    "角音疏肝",
    '五音疗疾',
    "丝弦养心，羽音安神",
    "羽音养肾，养肾最佳",
    "羽音养肾，减轻疲劳",
    "羽音养肾，平心静气",
    "羽音养肾，滋补肾精",
    "羽音养肾，养心安神",
    "羽音养肾",
    "羽调式音乐",
    "养脾音乐",
    "养脾音乐，舒神静心",
    "安稳情绪，疏肝理气",
    "宫音健脾，羽调益肾",
    "宫音健脾，调养五味",
    "宫音养脾，丝弦养心",
    "宫音养脾",
    "土音养脾",
    "土宫音，健脾养胃",
    "养脾合胃",
}