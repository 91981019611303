
import { Watch, Mixins, Component, Ref, InjectReactive } from "vue-property-decorator";
import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";
import ETable from "@/components/ETable.vue";
import { Form } from "element-ui/types";
import { cloneDeep } from "lodash";

import { appComposeGroupList, saveGroupType } from "@/api/appCompose";

const baseForm = {
    name: "",
    sortNum: 0,
}

@Component({
    components: {
        ETable,
    }
})
export default class groupType extends Mixins(Resize, TablePagination) {
    @InjectReactive() currentTab!: string | number;
    @Ref("validateForm") validateForm: Form;

    tableData = [];
    loading = false;

    columns = [
        { label: "分类名称", prop: "name" },
        { label: "排序", prop: "sortNum" },
        { label: "创建人", prop: "createdBy" },
        { label: "创建时间", prop: "createdTime" },
        { label: "操作", prop: "action" },
    ];

    dialogTitle = "";
    visible = false;

    formData = {
        id: "",
        name: "",
        sortNum: 0,
        merchantId: ""
    };

    formRules = {
        name: [{ required: true, message: "请输入分类名称" }],
    };

    brandName = "";
    merchantId = "";

    mounted() {
        this.windowResize(280);
    }

    getData() {
        this.loading = true;
        const params = {
            merchantId: this.$route.query.merchantId
        }
        appComposeGroupList(params).then((res) => {
            this.tableData = res.data;
        }).finally(() => {
            this.loading = false
        });
    };

    handleEdit(row) {
        this.dialogTitle = row.id ? "编辑" : "新增";
        if (row.id) {
            // const { name, sortNum } = row;
            Object.assign(this.formData, row);
        }
        this.visible = true;
    }

    dialogClose() {
        this.visible = false;
        this.formData = cloneDeep(baseForm);
        this.validateForm?.resetFields();
    }

    saveGroupType() {
        this.validateForm?.validate((valid) => {
            if (valid) {
                this.formData.merchantId = <string>this.$route.query.merchantId;
                const params = cloneDeep(this.formData);
                this.loading = true;
                saveGroupType(params).then((res) => {
                    this.$message.success("保存成功");
                    this.dialogClose();
                    this.getData();
                }).finally(() => {
                    this.loading = false;
                });
            }
            else {
                return false;
            }
        });
    }

    @Watch("$route", { immediate: true })
    routeChange() {
        console.log("routeChange")
        const path = this.$route.path;
        if (["/appCompose", "/merchantAppCompose"].includes(path)) {
            this.brandName = <string>this.$route.query.brandName;
            this.merchantId = <string>this.$route.query.merchantId;
        }
        // this.currentTabChange();
        // this.groupTypeList = [];
        // this.getGroupTypeList();
    }

    @Watch("currentTab", { immediate: true })
    currentTabChange() {
        if (this.currentTab != 2) return;
        this.getData();
    }
}
