
  import { Ref, Mixins, Component, InjectReactive, Watch } from "vue-property-decorator";
  import tablePagination from "@/mixins/tablePagination";
  import loading from "@/mixins/loading";
  import Resize from "@/mixins/resize";
  
  import ETable from "@/components/ETable.vue";
  import RichText from "@/components/rich-text.vue";
  import { healthRecordPlanList } from "@/api/healthRecord";
  
  import PlanDetail from "./planDetail.vue";
  
  @Component({
    components: {ETable,RichText,PlanDetail}
  })
  export default class HealthRecordPlan extends Mixins(loading, tablePagination, Resize) {
      @InjectReactive() currentTab !: number;

      planStatusOptions = [
        { label: "待完成计划", value: 1, count: 0 },
        { label: "已完成计划", value: 2, count: 0 },
        { label: "全部", value: "", count: 0 },
      ]

      queryForm = {
        planStatus: 1,
      };
  
      itemTotal = 0;
      tableData = [];
  
      columns = [
          { label: "ID", prop: "id" },
          { label: "归属门店", prop: "storeName" },
          { label: "计划时间", prop: "endTime" },
          { label: "小组类型", prop: "groupTypeDesc" },
          { label: "小组名称", prop: "groupName" },
          { label: "负责人", prop: "groupContactName" },
          { label: "负责人手机", prop: "contactPhone" },
          { label: "人数", prop: "totalPersons" },
          { label: "需检测人数", prop: "needDetectCount" },
          { label: "已检测人数", prop: "alreadyDetectCount" },
          { label: "创建人", prop: "createdBy" },
          { label: "创建时间", prop: "createdTime" },
      ]
  
      groupTypeOptions = [
          { label: "幼儿园", value: 1 },
          { label: "小学", value: 2 },
          { label: "养老院", value: 3 },
          { label: "其他", value: 4 },
      ]

      dialogVisible = false
      planRecord = {} as any;
  
      mounted() {
        this.windowResize(320);
      }
  
      searchData() {
          this.resetCurrent();
          this.getData();
      }
  
      @Watch("currentTab", { immediate: true })
      getData() {
        if (this.currentTab != 1) return;
        const params = {
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            // @ts-ignore
            status: (this.queryForm.planStatus == "") ? [1,2] : [this.queryForm.planStatus],
        }
        this.showLoading();
        
        healthRecordPlanList(params).then((res) => {
            const { plans, statusCounts } = res.data;
            this.tableData = plans.list;
            this.itemTotal = plans.total;

            this.tableData.forEach((el) => {
                el.groupTypeDesc = this.groupTypeOptions.find(item => item.value == el.groupType).label;
                el.endTime = el.endTime.split(" ")[0];
            })
            
            const { statusOne, statusTwo, totalCount } = statusCounts;
            this.planStatusOptions[0].count = statusOne;
            this.planStatusOptions[1].count = statusTwo;
            this.planStatusOptions[2].count = totalCount;
        })
      }

      viewDetail(row) {
        console.log("viewDetail", row);
        this.dialogVisible = true;
        this.planRecord = row;
      }

      handleClose() {
          this.dialogVisible = false;
      }
  }
  
  