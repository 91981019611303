import { service } from '@/ajax/request'
import { Id } from '@/types/common'
import { AxiosPromise } from 'axios'
import { Role, RolePermission, AllocMenu, AllocResource, AllRoleList } from '@/types/api/admin'


// 删除指定用户信息
export const deleteAdminById = (params: Id): AxiosPromise => (
  service({
    url: `/admin/delete/${params.id}`,
    method: 'POST',
    params
  })
)

// 添加角色
export const createRole = (params: Role): AxiosPromise => (
  service({
    url: `/role/create`,
    method: 'POST',
    params
  })
)

// 获取所有角色
export const allRole = (): AxiosPromise => (
  service({
    url: `/role/listAll`,
    method: 'GET',
    type: 'form'
  })
)

// 根据角色名称分页获取角色列表
export const allRoleList = (params: AllRoleList): AxiosPromise => (
  service({
    url: `/role/list`,
    method: 'GET',
    type: 'form',
    params
  })
)

// 批量删除角色
export const deleteRoles = (params: { ids: string }): AxiosPromise => (
  service({
    url: `/role/delete`,
    method: 'POST',
    type: 'form',
    params
  })
)

// 获取角色相关菜单
export const roleMenuList = (roleId: number): AxiosPromise => (
  service({
    url: `/role/listMenu/${roleId}`,
    method: 'GET',
    type: 'form'
  })
)

// 获取角色相关资源
export const roleMenuResource = (roleId: number): AxiosPromise => (
  service({
    url: `/role/listResource/${roleId}`,
    method: 'GET',
    type: 'form'
  })
)

// 修改角色权限
export const rolePermissionUpdate = (params: RolePermission): AxiosPromise => (
  service({
    url: `/role/permission/update`,
    method: 'POST',
    type: 'form',
    params
  })
)

// 修改角色
export const roleUpdate = (params: Role): AxiosPromise => (
  service({
    url: `/role/update/${params.id}`,
    method: 'POST',
    params
  })
)

// 修改角色状态
export const roleUpdateStatus = (id: number, status: number): AxiosPromise => (
  service({
    url: `/role/updateStatus/${id}`,
    method: 'POST',
    type: 'form',
    params: {
      status
    }
  })
)

// 给角色分配菜单
export const allocMenu = (params: AllocMenu): AxiosPromise => (
  service({
    url: `/role/allocMenu`,
    method: 'POST',
    params,
    type: 'form'
  })
)

// 获取相应角色权限
export const permissionByRoleId = (params: { roleId: number }): AxiosPromise => (
  service({
    url: `/role/permission/${params.roleId}`,
    method: 'GET',
    params,
    type: 'form'
  })
)

// 给角色分配资源
export const allocResource = (params: AllocResource): AxiosPromise => (
  service({
    url: `/role/allocResource`,
    method: 'POST',
    params,
    type: 'form'
  })
)

// 获取指定用户的角色
export const adminRoleById = (params: { adminId: number }): AxiosPromise => (
  service({
    url: `/admin/role/${params.adminId}`,
    method: 'GET',
    params
  })
)

export const getUserAuthorityInfo = (id: any) => (
  service({
      url: `/admin/user-info/${id}`,
      method: 'GET',
  })
)
