
    import {
        Vue,
        Component,
        PropSync,
        Ref,
        Watch
    } from "vue-property-decorator";
    
    import { savePresRelation } from "@/api/nurse";
    import { Form } from "element-ui";

    import RelationPresTransferWay from "./relationPresTransferWay.vue";
    import RelationPresTransferHome from "./relationPresTransferHome.vue";
    import RelationPresTransferSport from "./relationPresTransferSport.vue";
    
    @Component({
        components: {
            RelationPresTransferWay, RelationPresTransferHome, RelationPresTransferSport
        }
    })
    export default class NurseRelationPres extends Vue {
        @Ref("form") FormRef !: Form;
        @PropSync("form") dialogForm!: any;
        @PropSync("visible") dialogVisible !: boolean;

        saveLoading = false;

        presWayList = []
        presHomeList = []
        presSportList = []
    
        get getDialogTitle() {
            return "关联处方";
        }
    
        mounted(){

        }

        syncPresWay(list) {
            console.log("syncPresWay", list);
            this.presWayList = [...list]
        }

        syncPresHome(list) {
            console.log("syncPresHome", list);
            this.presHomeList = [...list]
        }

        syncPresSport(list) {
            console.log("syncPresSport", list);
            this.presSportList = [...list]
        }

    
        handleClose() {
            this.dialogVisible = false;
            this.FormRef?.resetFields();
        }
    
        submit() {
            this.FormRef.validate().then(() => {
                const params = {
                    presId: [...this.presWayList, ...this.presHomeList, ...this.presSportList],
                    ruleId: this.dialogForm.id,
                }
                savePresRelation(params).then((res) => {
                    console.log("res", res);
                    this.$message.success("操作成功")
                    this.handleClose();
                    this.$emit("syncList")
                })
            })
        }

        @Watch("dialogForm.presList", { immediate: true, deep: true })
        setStashList(list) {
            console.log("setStashList", list);
            if (!list && !list?.length) return
            this.dialogForm.stashWay = [...list?.filter(p => p.presType == 1)?.map((p) => p.id)];
            this.dialogForm.stashHome = [...list?.filter(p => p.presType == 2)?.map((p) => p.id)];
            this.dialogForm.stashSport = [...list?.filter(p => p.presType == 3)?.map((p) => p.id)];
        }
    
    
    }
    