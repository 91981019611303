
  import { Watch, Mixins, Component, Vue, Ref, Provide, ProvideReactive } from "vue-property-decorator";
  import PulseTaking from "./component/pulseTaking.vue";
  import Lung from "./component/lung.vue";
  import Tongue from "./component/tongue.vue";
  import Phy from "./component/phy.vue";
  import Height from "./component/height.vue";
  
  @Component({
    inject: [],
    components: {
      PulseTaking,
      Lung,
      Tongue,
      Phy,
      Height
    }
  })
  export default class PrescriptionDb extends Vue {
    @Ref("pulseTakingRef") readonly pulseTakingRef!: PulseTaking;
    @Ref("lungRef") readonly lungRef!: Lung;
    @Ref("tongueRef") readonly tongueRef!: Tongue;
    @Ref("phyRef") readonly phyRef!: Phy;
    @Ref("heightRef") readonly heightRef!: Height;
  
    @ProvideReactive() pageCurrentTab = "1";
  
    @ProvideReactive()
    merchantId = this.$route.query.merchantId;
    brandName = this.$route.query.brandName
    keyword = "";
    
    currentTab = "1";
    dialogFormVisible = false;
    
    productTypeOptions = [{ label: "项目产品库", value: 1 }, { label: "居家产品库", value: 2 }, { label: "其他库", value: 3 }]
  
    deviceTypeOptions: Array<{ deviceTypeId: number, deviceTypeName: string }> = [];
  
    saveLoading = false;
    formData = {
      productType: 1,
      deviceType: "",
      productName: "",
    }
    formRules = {
      productType: [{ required: true, message: "请选择产品分类" }],
      deviceType: [{ required: true, message: "请选择设备类型" }],
      productName: [{ required: true, message: "请输入产品名称" }],
    }
  
    paginationInfo = {
      currentPage: 1,
      pageSize: 20,
      itemTotal: 0,
    }
  
    syncTotal(total) {
      this.paginationInfo.itemTotal = total;
    }
  
    handleSizeChange(val: number) {
      this.paginationInfo.pageSize = val;
      this.searchPrescriptionDb();
    }
    handleCurrentChange(val: number) {
      this.paginationInfo.currentPage = val;
      this.searchPrescriptionDb();
    }
  
    resetPage() {
      this.paginationInfo.currentPage = 1;
    }
  
    // 触发子组件的查询
    searchPrescriptionDb(isFirst = false) {
      if (isFirst) {
        this.paginationInfo.currentPage = 1;
      }
      const queryParam = { symptomName: this.keyword, pageNum: this.paginationInfo.currentPage, pageSize: this.paginationInfo.pageSize };
      
      if (this.currentTab == "1") {
        this.pulseTakingRef?.prescriptionSymptom(this.currentTab, queryParam)
      }
      if (this.currentTab == "2") {
        this.tongueRef?.prescriptionSymptom(this.currentTab, queryParam)
      }
      if (this.currentTab == "3") {
        this.phyRef?.prescriptionSymptom(this.currentTab, queryParam)
      }
      if (this.currentTab == "4") {
        this.lungRef?.prescriptionSymptom(this.currentTab, queryParam)
      }
      if (this.currentTab == "5") {
        this.lungRef?.prescriptionSymptom(this.currentTab, queryParam)
      }
    }
  
    @Watch("currentTab", { immediate: true })
    setProductType(value: string) {
      this.formData.productType = Number(value);
      this.keyword = "";
      this.pageCurrentTab =  value;
    }
  
  }
  