
import { Mixins, Component, PropSync } from 'vue-property-decorator'
import { getProductCategoryList, getProductList } from "@/api/prescriptionDb";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import { ProductSaleStatusDescEnum, ProductSaleStatusDescType } from "@/enum/product.enum";
import ETable from '@/components/ETable.vue';

@Component({
    components: { ETable }
})
export default class selectProduct extends Mixins(loading, tablePagination, Resize) {
    @PropSync("visible", { default: false }) relatedDialogVisible !: boolean;

    columns = [
        { label: " ", prop: "select", width: 55, fix: "left" },
        { label: "商品ID", prop: "spu", minWidth: "120" },
        { label: "商品分类", prop: "", minWidth: "120", formatter: (row: any) => row.category?.name },
        { label: "商品名称", prop: "name", minWidth: "120" },
        { label: "上下架状态", prop: "", minWidth: "100", formatter: (row: any) => this.getSellStatusDesc(row.sellStatus) },
    ]

    dataSource = []
    itemTotal = 0;

    queryForm = {
        categoryId: "",
        name: "",
        spu: "",
    };

    spu = "";
    id = 0;

    mounted() {
        this.initProductCategoryList();
        this.getData();
    }

    productCategoryOptions = [];
    initProductCategoryList() {
        getProductCategoryList({ pageSize: 1000, pageNum: 1 }).then((res) => {
            this.productCategoryOptions = res.data.list;
        })
    }

    searchHandle(queryForm) {
        getProductList({ ...queryForm, pageNum: this.currentPage, pageSize: this.pageSize }).then(res => {
            this.dataSource = res.data.list;
            this.itemTotal = res.data.total;
        })
    }

    handleSelect(row) {
        this.spu = row.spu;
    }

    relatedDialogClose() {
        this.relatedDialogVisible = false;
        this.queryForm = {
            categoryId: "",
            name: "",
            spu: "",
        };
        this.spu = "";
        // this.handleRemove();
    }

    RelatedProductConfirm() {
        this.$emit("syncProductList", this.spu);
        this.relatedDialogClose();
    }

    getSellStatusDesc(status: ProductSaleStatusDescType) {
        return ProductSaleStatusDescEnum[status];
    }

    // @Watch('relatedDialogVisible')
    getData() {
        // if (!this.relatedDialogVisible) return;
        this.searchHandle(this.queryForm);
    }
}

