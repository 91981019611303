import { service } from '@/ajax/request'
import { AxiosPromise } from 'axios'



export const list = (params: any) => (
  service({
    url: '/app/appCompose/merchantList',
    method: 'GET',
    params,
    type: 'form'
  })
)



export const saveAppCompose = (params: any) => (
  service({
    url: '/app/appCompose/save',
    method: 'POST',
    params,
    // type: 'form'
  })
)

export const appComposeDelete = (params: any) => (
    service({
        url: `/app/appCompose/delete/${params.id}`,
        method: 'POST',
        params,
        type: 'form'
    })
)
export const appComposeGroupList = (params) => (
    service({
        url: `/app/app-compose-group/list`,
        params,
        method: 'GET',
    })
)

export const saveGroupType = (params: any) => (
    service({
        url: '/app/app-compose-group/saveOrUpdate',
        method: 'POST',
        params,
    })
)



