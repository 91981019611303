
import { Form } from "element-ui";
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    InjectReactive
} from "vue-property-decorator";

import { updateNursePresBaseInfoWY, getPresOneWY } from "@/api/nurse";

@Component({
    components: {
    }
})
export default class NursePresAddXz extends Vue {
    @Ref("formRef") formRef!: Form;
    @InjectReactive("addFormData") addFormData!: any;

    formData = {
        level: "",
        distance: "15~20",
    }

    rules = {
        level: { required: true, message: "请选择建议等级", trigger: ['blur', 'change'] },
        distance: { required: true, message: "请输入建议距离", trigger: ['blur', 'change'] },
    }

    levelOptions = Array.from(Array(11).keys()).map((item) => {
        return item;
    })

    mounted() {
        this.getDetail();
    }

    getDetail() {
        if (this.addFormData.healthType == 4 && this.addFormData.id) {
            getPresOneWY(this.addFormData.id).then((res) => {
                const data = res.data;
                this.formData = { ...data };
            })
        }
    }

    validateForm() {
        return this.formRef?.validate().then(() => {
            const params = {
                customId: this.addFormData.id,
                ...this.formData,
            }
            updateNursePresBaseInfoWY(params)
            return this.formData;
        })
    }
}

