<template>
  <div class="content-box">
    <div class="content-header" ref="searchBoxRef">
      <el-select class="input" v-model="isEnable" clearable placeholder="启禁状态">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select
        clearable
        v-model="deviceTypeId"
        class="input"
        @focus="getDeviceType"
        @change="deviceFirstChange"
        placeholder="选择设备类型"
      >
        <el-option
          v-for="item in deviceTypeList"
          :key="item.id"
          :label="item.deviceName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <!-- 先隐藏，后续显示 -->
      <el-select
        clearable
        v-model="deviceClassId"
        class="input"
        placeholder="选择设备分类"
        @focus="getSubDeviceTypeList('deviceCategoryOptions', deviceTypeId)"
        @change="deviceClassNameChange"
      >
        <el-option
          v-for="item in deviceCategoryOptions"
          :key="item.id"
          :label="item.deviceClassName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        clearable
        v-model="deviceNameId"
        class="input"
        @focus="getSearchDeviceTypeByParentId"
        placeholder="请选择关联设备"
      >
        <el-option
          v-for="item in deviceSubTypeList"
          :key="item.id"
          :label="getSubType(item)"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        clearable
        v-model="isNoScan"
        class="input"
        placeholder="请选择免扫码开关"
      >
        <el-option label="开启" :value="1"></el-option>
        <el-option label="关闭" :value="0"></el-option>
      </el-select>
      <el-input
        :value="deviceCode"
        @input="changeValue($event, 'deviceCode')"
        class="input"
        clearable
        placeholder="输入设备编号"
      ></el-input>
      <el-date-picker
        :value="selectDate"
        @input="changeValue($event, 'selectDate')"
        format="yyyy-MM-dd HH:mm:ss"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        class="select-date"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      ></el-date-picker>
          <el-input
        :value="storeName"
        @input="changeValue($event, 'storeName')"
        class="input"
        maxlength="100"
        clearable
        placeholder="输入关联门店名称"
      ></el-input>
      <el-button type="plain" class="cotent-btn" @click="handleSearch"
        >搜索</el-button
      >
      <el-button
        type="plain"
        class="cotent-btn-big"
        icon="el-icon-plus"
        @click="handleEdit"
        >新增设备</el-button
      >
    </div>
    <div class="table-list">
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="tableMaxheight"
        :header-cell-style="headerCellStyle"
        border
        v-loading="loading"
        :cell-style="cellStyle"
      >
        <el-table-column prop="id" label="设备ID"> </el-table-column>
        <el-table-column prop="deviceName" label="设备型号"> </el-table-column>
        <el-table-column prop="deviceClassName" label="设备分类"> </el-table-column>

        <el-table-column prop="deviceTypeName" label="设备类型">
        </el-table-column>

        <el-table-column prop="isNoScan" label="免扫码开关" width="100">
          <template #default="{row}">
            {{row.isNoScan ? "开启" : "关闭"}}
          </template>
        </el-table-column>

        <el-table-column prop="deviceCode" label="设备编码"> </el-table-column>
        <el-table-column prop="createTime" label="添加时间"> </el-table-column>
        <!-- <el-table-column prop="expireTime" label="到期时间">
                <template slot-scope="scope">
                    {{ scope.row.limitStatus == 1 ? "无限期" : scope.row.expireTime }}
                  </template>
        </el-table-column> -->
        <el-table-column prop="storeName" label="关联门店">
          <template slot-scope="scope">
            <span v-if="!scope.row.storeName">未关联</span>
            <router-link v-else :to="'/storeList?storeName=' + scope.row.storeName" class="a-span">{{ scope.row.storeName }}</router-link>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="设备状态">
          <template slot-scope="scope">
            <div class="device-online" v-if="scope.row.status == 1">
              {{ getStatus(scope.row.status) }}
            </div>
            <div class="device-offline" v-else>
              {{ getStatus(scope.row.status) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="deviceMessageCode" label="盒子编码">
          <template slot-scope="scope">
            <div class="device-code">{{ scope.row.deviceMessageCode }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="runtime" label="运行时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="modifiedBy" label="操作人"></el-table-column>
        <el-table-column prop="modifiedTime" label="最后操作时间"></el-table-column>
        <el-table-column label="操作" width="180" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              class="device-online"
              v-if="scope.row.enableState == 0"
              type="text"
              @click="handleEnableState(scope.row)"
              >开启</el-button
            >
            <el-button
              class="device-offline"
              v-if="scope.row.enableState == 1"
              type="text"
              @click="handleEnableState(scope.row)"
              >禁用</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        style="margin-top: 20px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="itemTotal"
      ></el-pagination>

      <el-dialog
        title="新增/编辑设备"
        :visible.sync="dialogVisible"
        width="460px"
        :before-close="dialogClose"
      >
        <div class="edit-box">
          <el-form
            label-position="left"
            label-width="100px"
            :model="formData"
            ref="validateForm"
            :rules="rules"
          >
            <el-form-item label="设备类型:" prop="deviceTypeId">
              <el-select
                v-model="formData.deviceTypeId"
                class="dialogInput"
                remote
                :remote-method="getDeviceType"
                @focus="getDeviceType"
                @change="deviceTypeChange"
                :filterable="false"
                placeholder="选择设备类型"
              >
                <el-option
                  v-for="item in deviceTypeList"
                  :key="item.id"
                  :label="item.deviceName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备分类:"  prop="deviceClassId">
              <el-select
                v-model="formData.deviceClassId"
                class="dialogInput"
                placeholder="选择设备分类"
                @focus="getSubDeviceTypeList('formDeviceCategoryOptions', formData.deviceTypeId)"
                @change="formDeviceClassNameChange"
              >
                <el-option
                  v-for="item in formDeviceCategoryOptions"
                  :key="item.id"
                  :label="item.deviceClassName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="关联设备:"  prop="deviceNameId">
              <el-select
                clearable
                v-model="formData.deviceNameId"
                class="dialogInput"
                @focus="getDeviceTypeByParentId"
                placeholder="请选择关联设备"
              >
                <el-option
                  v-for="item in deviceSubTypeList"
                  :key="item.id"
                  :label="getSubType(item)"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="协议类型:"  prop="protocolType">
              <el-select
                clearable
                v-model="formData.protocolType"
                class="dialogInput"
                placeholder="请选择协议类型"
              >
                <el-option
                  v-for="item in protocolTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="免扫码开关:"  prop="isNoScan" v-if="formData.deviceTypeId == 3">
              <el-switch v-model="formData.isNoScan"></el-switch>
            </el-form-item>
            <el-form-item label="设备编码:">
              <el-input
                v-model="formData.deviceCode"
                class="dialogInput"
                placeholder="请输入设备编码"
              ></el-input>
            </el-form-item>
            <el-form-item label="关联门店:" prop="storeId">
              <el-select
                clearable
                v-model="formData.storeId"
                class="dialogInput"
                remote
                :remote-method="getAllStoreList"
                @focus="getAllStoreList"
                filterable
                placeholder="请选择关联门店"
              >
                <el-option
                  v-for="item in allStoreList"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备运行时间:" prop="runtime">
              <el-input-number class="dialogInput" v-model="formData.runtime" placeholder="请输入运行时间（分钟）" :min="0" :max="99999999" :controls="false" :precision="0"></el-input-number>
            </el-form-item>
            <el-form-item label="设备图片:" prop="deviceImg">
              <UploadImg :fileList.sync="formData.deviceImg" :limit="3" :size="10" />
              <p class="tip">只支持jpg/png格式</p>
            </el-form-item>
            <!-- <el-form-item label="到期时间:" prop="expireTime">
              <el-select @change="limitStatusChange" style="width: 96px;margin-right: 4px" v-model="formData.limitStatus" placeholder="请选择">
                <el-option
                  v-for="item in limitStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker
                style="width: 200px;"
                v-model="formData.expireTime"
                :picker-options="pickerOptions"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="formData.limitStatus == 1"
                class="dialogInput"
              ></el-date-picker>
            </el-form-item> -->
            <el-form-item label="备注:">
              <el-input
                v-model="formData.remark"
                class="dialogInput"
                type="textarea"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button type="primary" @click="dialogConfirm">提交</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { deviceList, save, update, updateEnableState } from "@/api/device";
import { allStoreList } from "@/api/store";
import { getDeviceTypeList, getDeviceTypeByParentId ,getDeviceSubTypeList, getSubDeviceTypeList } from "@/api/deviceType";

import UploadImg from "@/components/uploadImg.vue";

// 工具
import deepCopy from "@/utils/deepCopy";
import dayjs from "dayjs";
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

const initFormData = {
  deviceNameId: "",
  deviceTypeId: "",
  deviceCode: "",
  protocolType: 0,
  isNoScan: false,
  storeId: "",
  runtime: "",
  remark: "",
  selectDate:'',
  expireTime:null,
  deviceImg: [],
};

@Component({
  components: {UploadImg}
})
export default class StoreList extends Mixins(loading, tablePagination, Resize) {
  deviceCode = null;
  deviceTypeId = "";
  deviceClassId = "";
  isEnable = "";
  isNoScan = "";
  deviceCategoryOptions = [];
  formDeviceCategoryOptions = [];
  selectDate = [];
  tableData = [];
  itemTotal = 0;
  dialogVisible = false;
  deviceSubTypeList = [];
  deviceNameId = "";
  deviceName = "";
  storeName = "";
  deviceTypeList = [];
  allStoreList = [];
  formData = {
    deviceNameId: "",
    deviceClassId: "",
    deviceTypeId: "",
    deviceCode: "",
    protocolType: 0,
    isNoScan: false,
    storeId: "",
    runtime: "",
    remark: "",
    selectDate:'',
    expireTime:null,
    limitStatus: 1,
    deviceImg: [],
  };
  limitStatusOptions = [{ label: "无限期", value: 1 },{ label: "自定义", value: 0 }]
  rules = {
    deviceTypeId: [{ required: true, message: "请选择设备类型", trigger: "change" }],
    deviceClassId: [{ required: true, message: "请选择设备分类", trigger: "change" }],
    deviceNameId: [{ required: true, message: "请选择关联设备", trigger: "change" }],
    protocolType: [{ required: true, message: "请选择协议类型", trigger: "change" }],
    isNoScan: [{ required: true, type: "boolean", message: " ", trigger: "change" }],
    storeId: [{ required: true, message: "请选择关联门店", trigger: "change" }],
    expireTime: [ { required: true, validator: this.validatorExpireTime } ]
  };
  protocolTypeOptions = [
    {
      label: "MQTT",
      value: 0
    },
    {
      label: "天力能API",
      value: 1
    }
  ]
  // 0-MQTT,1-天力能API

  isNoScanOptions = [
    {
      label: "MQTT",
      value: 0
    },
    {
      label: "天力能API",
      value: 1
    }
  ]
  pickerOptions = {
    disabledDate: (date) => {
      // 获取今天的日期
      const today = dayjs(new Date()).startOf("d");
      // 将选中的日期转换为Date对象
      const selectedDate = dayjs(new Date(date)).startOf("d");
      // 比较选中的日期是否在今天之前
      return selectedDate.isSameOrBefore(today);
    }
  }

  statusOptions = [{ label: "已启用", value: 1 }, { label: "已禁用", value: 0 }];

  validatorExpireTime(rule, value, callback) {
    if (this.formData.limitStatus == 1) { // 无限制，不用输入时间
      return callback();
    }
    if (this.formData.limitStatus == 0) { // 有限制，需要时间
      if (!this.formData.expireTime) {
        return callback(new Error("请选择到期时间"))
      }
    }
    return callback();
  }
  limitStatusChange(value) {
    if (value == 1) {
      this.formData.expireTime = "";
    }
  }

  deviceFirstChange() {
    this.deviceClassId = "";
    this.deviceNameId = "";    
  }

  getSubDeviceTypeList(key, parentId) {
    getSubDeviceTypeList({ parentId }).then((res) => {
      this[key] = res.data || [];
    }).catch(() => {
      this[key] = [];
    })
  }

  deviceClassNameChange($event) {
    this.deviceNameId = "";
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return "在线";
      case 0:
        return "离线";
      case 2:
        return "获取失败";
      case 3:
        return "激活";
      default:
        return "-";
    }
  }

  mounted() {
    this.getData();
    this.getDeviceType();
    this.getAllStoreList();
    this.windowResize();
  }

  @Watch("formData.deviceNameId")
  setSubType(deviceNameId, oldDeviceNameId) {
    if (oldDeviceNameId || !this.formData.runtime) {
      this.deviceSubTypeList.map((val) => {
        if (val.id == deviceNameId) {
          this.formData.runtime = val.runtime;
        }
      });
    }
  }

  getName(target, id, keyId, keyName) {
    let name = "";
    target.forEach((item) => {
      if (item[keyId] == id) {
        name = item[keyName];
      }
    });
    return name;
  }

  getData() {
    this.showLoading();
    deviceList({
      isEnable: this.isEnable,
      deviceClassId: this.deviceClassId,
      deviceCode: this.deviceCode,
      storeName:this.storeName,
      deviceTypeId: this.deviceTypeId,
      deviceNameId: this.deviceNameId || null,
      fromDate: this.selectDate && this.selectDate.toString().split(",")[0],
      toDate: this.selectDate && this.selectDate.toString().split(",")[1],
      isNoScan: this.isNoScan,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    })
      .then((res) => {
        this.hideLoading();
        this.tableData = res.data.list;
        this.itemTotal = res.data.total;
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  getDeviceType(str) {
    if (typeof str == "object") {
      str = "";
    }
    getDeviceTypeList({})
      .then((res) => {
        this.deviceTypeList = res.data;
      })
      .catch((err) => {});
  }

  deviceTypeChange($event) {
    // 选择设备类型之后，要把管理设备清空掉
    if (!$event) return;
    this.formData.deviceClassId = ""
    this.formData.deviceNameId = ""
  }

  formDeviceClassNameChange($event) {
    if (!$event) return;
    this.formData.deviceNameId = ""
  }

  getSearchDeviceTypeByParentId() {
    getDeviceTypeByParentId({
      mainTypeId: this.deviceTypeId,
      subTypeId: this.deviceClassId,
    }).then((res) => {
        this.deviceSubTypeList = res.data;
      })
      .catch((err) => {});
  }

  getDeviceTypeByParentId() {
    if(this.formData.deviceTypeId){
      getDeviceTypeByParentId({
        mainTypeId: this.formData.deviceTypeId,
        subTypeId: this.formData.deviceClassId,
    })
      .then((res) => {
        this.deviceSubTypeList = res.data;
      })
      .catch((err) => {});
    }else{
      getDeviceSubTypeList()
      .then((res) => {
        this.deviceSubTypeList = res.data;
      })
      .catch((err) => {});
    }
  }

  getSubType(subTypeObject) {
    return subTypeObject.deviceName + "[" + subTypeObject.runtime + "分钟]";
  }

  getAllStoreList(str) {
    if (typeof str == "object") {
      str = "";
    }
    allStoreList({
      storeName: str,
    })
      .then((res) => {
        this.allStoreList = res.data;
      })
      .catch((err) => {});
  }

  // 头部输入
  changeValue(val, type) {
    console.log(val, type);
    this[type] = val;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }

  handleEdit(row) {
    let {
      id,
      deviceName,
      deviceClassName = "",
      deviceNameId = "",
      deviceClassId = "",
      deviceTypeId = "",
      deviceCode = "",
      storeId = "",
      protocolType = 0,
      storeName,
      runtime,
      remark,
      expireTime,
      deviceImg,
      limitStatus = 1,
      isNoScan = false,
    } = row;
    this.dialogVisible = true;
    let showExpireTime = (new Date(expireTime).getFullYear() > 8888 ? null : expireTime)
    this.$nextTick(() => {
      this.formData = {
      id,
      deviceName,
      deviceClassId,
      deviceNameId,
      deviceTypeId,
      deviceCode,
      storeId,
      runtime,
      remark,
      expireTime: limitStatus == 1 ? "" : showExpireTime,
      limitStatus,
      deviceImg: deviceImg || [],
      protocolType,
      isNoScan: Boolean(isNoScan),
    };
    if (id) {
      this.deviceSubTypeList = [...this.deviceSubTypeList, { id: deviceNameId, deviceName, runtime }];
      this.formDeviceCategoryOptions = [{ id: deviceClassId, deviceClassName }];
      this.allStoreList = [{ id: storeId, storeName: storeName, storeId }]
    }
    })
  }


  @Watch("formData.protocolType")
  setScanFlag(protocolType) {
    if (protocolType == 1){
      this.formData.isNoScan = false;
    }
  }

  dialogClose() {
    this.formData = deepCopy(initFormData);
    this.formData.id = "";
    this.$nextTick(() => {
      this.$refs["validateForm"].resetFields();
      this.dialogVisible = false;
    })
  }
  dialogConfirm() {
    this.$refs["validateForm"].validate((valid) => {
      if (valid) {
        this.formData.deviceName = this.getName(
          this.deviceSubTypeList,
          this.formData.deviceNameId,
          "id",
          "deviceName"
        );
        this.formData.deviceTypeName = this.getName(
          this.deviceTypeList,
          this.formData.deviceTypeId,
          "id",
          "deviceName"
        );
        this.formData.storeName = this.getName(
          this.allStoreList,
          this.formData.storeId,
          "id",
          "storeName"
        );
        if (this.formData.limitStatus == 1) {
          this.formData.expireTime = "";
        } else {
          this.formData.expireTime = this.formData.expireTime?.replace("00:00:00", "23:59:59");
        }
        this.formData.deviceImg = this.formData.deviceImg?.map((img, index) => ({ sort: index, deviceId: this.formData.id, url: img.url }))
        if (this.checkScanFLag()) {
          this.$confirm("确认打开免扫码开关？", '提示', {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: 'warning'
          }).then(() => {
            this.confirmAction();
          });
          return;
        }
        this.confirmAction();
      } else {
        return false;
      }
    });
  }

  checkScanFLag() {
    if (this.formData.isNoScan == 1) {
      return true;
    }
    return false;
  }

  confirmAction() {
    this.formData.isNoScan = Number(this.formData.isNoScan);
    if (this.formData.id) {
      update(this.formData)
        .then((res) => {
          this.dialogClose();
          this.getData();
        })
        .catch((err) => { });
    } else {
      save(this.formData)
        .then((res) => {
          this.dialogClose();
          this.resetPages();
          this.getData();
        })
        .catch((err) => { });
    }
  }
  handleEnableState(row) {
    const content = row.enableState == 1 ? "禁用" : "启用";
    this.$confirm(`确认${content}该设备？`, {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
    }).then(() => {
      const params = {
        deviceId: row.id,
        status: row.enableState == 1 ? 0 : 1,
      };
      updateEnableState(params).then((res) => {
        this.getData();
      })
      .catch((err) => {});
    });
  }
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
