
import { Watch, Component, Vue, Ref, PropSync, Prop } from 'vue-property-decorator';

import { nursePresWayList } from "@/api/nurse";

@Component
export default class RelationPresTransferSport extends Vue {
   @Prop({ type: Array, default: () => [] }) stashList !: Array<any>;
   @Prop({ default: true }) clearStashListFlag !: boolean;
   @Ref("transferRef") transferRef: any;

   stashPresSport = [];
   presSport = [];

   mounted() {
       this.getPresSport();
   }
   getPresSport() {
    nursePresWayList({ pageNum: 1, pageSize: 1000, presType: 3 }).then((res) => {
           console.log("nursePresSportList", res.data)
           res.data.list.forEach((item) => {
                item.presName = item.presName + `（${item.status == 1 ? "启用" : "禁用"}）`;
            });
           this.presSport = res.data.list;
       });
   };

   closeTransfer() {
       this.transferRef?.clearQuery("left");
       this.transferRef?.clearQuery("right");
       if (this.clearStashListFlag) this.clearStashList();
   };

   clearStashList() {
       this.stashPresSport = [];
   };

   getStashStoreInfoList() {
       return this.stashPresSport.map((item) => {
           const { id, storeName } = this.presSport.find((store) => store.id == item) || {};
           return {
               id,
               storeName
           };
       });
   };

   confirmTransfer() {
       this.$emit("syncPresSport", this.stashPresSport);
       this.$emit("syncStoreInfoList", this.getStashStoreInfoList());
       this.closeTransfer();
   };

   @Watch("stashPresSport")
    syncStashPresWay(list) {
        this.$emit("syncPresSport", [...list]);
    }

   @Watch("stashList")
   @Watch("presSport", { deep: true })
   handleStashList(list) {
       this.stashPresSport = [...this.stashList];
   }
}
