export enum GenderEnum {
    "通用",
    "男性",
    "女性",
}

export enum GenderEnum2 {
    "通用",
    "男",
    "女",
}
