
import { Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import BannerForm from "./components/bannerForm.vue";
import { cloneDeep, debounce, uniqWith } from "lodash";
import { appBannerList } from "@/api/banner";
import ETable from '@/components/ETable.vue';

const initForm = {
    code: "",
    displayType: 1,
    displayScale: 1,
    id: 0,
    imgs: [
        {
            imgUrl: "",
            linkType: 0,
            linkUrl: "",
            storeListIds: []
        }
    ],
    remark: "",
    status: true,
    title: ""
}

@Component({
    components: {
        BannerForm,
        ETable
    }
})
export default class banner extends Mixins(loading, tablePagination, Resize) {
    tableData = [];
    itemTotal = 0;

    columns = [
        { label: "ID", prop: "id", width: "120" },
        { label: "编码", prop: "code", minWidth: "100" },
        { label: "标题", prop: "title", minWidth: "100" },
        { label: "状态", prop: "statusDesc", minWidth: "100" },
        { label: "操作人", prop: "modifiedBy", minWidth: "100" },
        { label: "最后操作时间", prop: "modifiedTime", minWidth: "100" },
        { label: "备注", prop: "remark", minWidth: "200" },
        { label: "操作", prop: "action", width: 120, fixed: "right" }
    ];

    form = {
        code: "",
        displayType: 1,
        displayScale: 1,
        id: 0,
        imgs: [
            {
                imgUrl: [],
                linkType: 0,
                linkUrl: ""
            }
        ],
        remark: "",
        status: true,
        title: ""
    };

    formVisible = false;
    dialogTitle = "";

    brandName = this.$route.query.brandName || "";

    mounted() {
        this.windowResize(270)
        this.getData();
    }

    getData() {
        this.showLoading();
        appBannerList({
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            merchantId: this.$route.query.merchantId
        }).then((res) => {
            this.hideLoading();
            res.data.list.forEach((el: any) => {
                el.statusDesc = el.status ? "启用" : "禁用";
            });
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    addBanner() {
        this.dialogTitle = "新增";
        this.formVisible = true;
        this.$nextTick(() => {
            Object.assign(this.form, initForm);
        });
    };

    edit(row) {
        row = cloneDeep(row);
        this.dialogTitle = "编辑";
        this.formVisible = true;
        this.$nextTick(() => {
            Object.assign(this.form, {
                ...row,
                imgs: row.imgs?.map((el: any) => ({
                    imgUrl: [{ url: el.imgUrl }],
                    linkType: el.linkType,
                    linkUrl: el.linkUrl
                }))
            });
        });
    };
}
