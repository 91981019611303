import { service } from '@/ajax/request'

export const getSolarTermsList = (params: any) => (
    service({
        url: '/nurse-solar-terms/get-list',
        method: 'POST',
        params,
    })
)

export const setCurrentSolarTerms = (id: any) => (
    service({
        url: `/nurse-solar-terms/set-current-term/${id}`,
        method: 'POST',
    })
)

export const editSolarTerms = (params: any) => (
    service({
        url: `/nurse-solar-terms/update-one`,
        method: 'POST',
        params,
    })
)