
import { Component, Ref, PropSync, Vue, Watch } from "vue-property-decorator";
import { Form } from "element-ui";
import { createUpdateBanner, getBannerStoreList } from "@/api/banner";
import SelectProduct from "./selectProduct.vue";
import SelectStore from "./selectStore.vue";
import baseUploadImg from "@/components/uploadImg.vue";

@Component({
    components: {
        baseUploadImg,
        SelectProduct,
        SelectStore
    }
})
export default class bannerForm extends Vue {
    @Ref("formRef") validateFormRef: Form;
    // @Ref("selectProductRef") readonly selectProductRef !: SelectProduct;
    @PropSync("form") modelForm !: any;
    @PropSync("visible") modelVisible !: boolean;
    @PropSync("title") dialogTitle !: string;

    formRule = {
        code: [{ required: true, message: "请输入" }],
        title: [{ required: true, validator: this.trimValidator, message: "请输入" }],
        displayScale: [{ required: true, message: "请选择" }],
        displayType: [{ required: true, message: "请选择" }],
        status: [{ required: true, message: "请选择" }],
        imgUrl: [{ type: "array", required: true, message: "请上传" }],
        linkUrl: [{ required: true, message: "请完善关联信息" }],
    };
    bannerItemIndex = 0;
    storeDialogVisible = false;
    stashStoreList = [];
    actionImgsIndex = 0;

    relatedDialogVisible = false;

    trimValidator(rule, value, callback) {
        const trimmedValue = value.trim();
        if (!trimmedValue) {
            callback(new Error("请输入"));
        } else {
            callback();
        }
    }
    addProduct(index) {
        this.bannerItemIndex = index;
        this.relatedDialogVisible = true;
    }
    syncProductList(spu) {
        if (!this.modelForm.imgs[this.bannerItemIndex]) return;
        this.modelForm.imgs[this.bannerItemIndex].linkUrl = spu;
    }
    addRow(index) {
        if (this.modelForm.imgs.length >= 9) {
            return;
        }
        this.modelForm.imgs.push({
            imgUrl: [],
            linkType: 0,
            linkUrl: "",
            storeList: [],
            storeListIds: []
        });
    }
    delRow(index) {
        this.modelForm.imgs.splice(index, 1);
    }
    linkTypeChange(event, imgInfo) {
        // if (event === 1) {
            imgInfo.linkUrl = "";
        // }
    }
    syncStoreList(event) {
        console.log(event);
    }
    syncStoreInfoList(event) {
        this.modelForm.imgs[this.actionImgsIndex].storeList = event;
        this.modelForm.imgs[this.actionImgsIndex].storeListIds = event.map((el) => el.id);
    }
    selectStore(event) {
        this.storeDialogVisible = true;
        this.actionImgsIndex = event;
        const img = this.modelForm.imgs[event];
        this.stashStoreList = img.storeList?.map((el) => el.id) || [];
    }
    dialogCloseFun() {
        this.validateFormRef.resetFields();
        this.modelForm.id = "";
        this.modelForm.imgs = [
            {
                imgUrl: [],
                linkType: 0,
                linkUrl: "",
                storeList: [],
                storeListIds: ""
            }
        ];
        this.stashStoreList = [];
        this.modelVisible = false;
    }
    dialogSave() {
        this.validateFormRef.validate().then(() => {
            this.modelForm.title = this.modelForm.title.trim();
            this.modelForm.remark = this.modelForm.remark.trim();
            this.modelForm.merchantId = this.$route.query.merchantId;
            const { imgs, ...otherInfo } = JSON.parse(JSON.stringify(this.modelForm));
            const params = {
                ...otherInfo,
                imgs: imgs.map((el) => ({
                    linkUrl: el.linkUrl,
                    linkType: el.linkType,
                    imgUrl: el.imgUrl[0]?.url,
                    storeList: el.storeList?.map((store) => ({ storeId: store.id, storeName: store.storeName }))
                }))
            };
            createUpdateBanner(params).then((res) => {
                this.dialogCloseFun();
                this.$emit("syncList");
                this.$message.success("保存成功");
            });
        });
    }

    @Watch("modelForm.code", { immediate: true })
    handler(bannerCode) {
        if (!bannerCode) return;
        getBannerStoreList({code: bannerCode, merchantId: this.$route.query.merchantId} ).then((res) => {
            this.modelForm.imgs = (res.data.imgs || []).map((el) => ({
                imgUrl: [{ url: el.imgUrl }],
                linkType: el.linkType,
                linkUrl: el.linkUrl,
                storeListIds: el.storeList?.map((store) => store.storeId),
                storeList: el.storeList?.map((store) => ({
                    id: store.storeId,
                    storeName: store.storeName
                }))
            }));
        });
    }
}
