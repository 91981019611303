import { service } from '@/ajax/request'
import { AxiosPromise } from 'axios'

export const nursePresTermList = (params: any = {}): AxiosPromise<any> => (
    service({
      url: `/nurse-pres-manage/get-all-terms`,
      method: 'POST',
      params
    })
  )

export const nursePresWayList = (params: any): AxiosPromise<any> => (
    service({
      url: `/nurse-pres-manage/get-page`,
      method: 'POST',
      params
    })
  )


export const updateNursePresBaseInfo = (params: any): AxiosPromise<any> => (
    service({
        url: `/nurse-pres-manage/create-or-update-base-info`,
        method: 'POST',
        params
    })
)

export const updateNursePresBaseInfoJM = (params: any): AxiosPromise<any> => (
    service({
        url: `/nurse-pres-manage/create-or-update-energy-hand`,
        method: 'POST',
        params
    })
)

export const updateNursePresBaseInfoXZ = (params: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/create-or-update-stream-ship`,
      method: 'POST',
      params
  })
)

export const updateNursePresBaseInfoWY = (params: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/create-or-update-warming`,
      method: 'POST',
      params
  })
)
export const updateNursePresBaseInfoWT = (params: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/create-or-update-warming-stomach`,
      method: 'POST',
      params
  })
)
export const updateNursePresBaseInfoAJ = (params: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/create-or-update-moxa`,
      method: 'POST',
      params
  })
)
export const getPresOne = (id: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/get-one-base/${id}`,
      method: 'POST',
  })
)

export const getPresOneAJ = (id: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/get-one-custom_moxa/${id}`,
      method: 'POST',
  })
)

export const getPresOneJM = (id: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/get-one-custom_energy_hand/${id}`,
      method: 'POST',
  })
)

export const getPresOneXZ = (id: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/get-one-custom_steam_ship/${id}`,
      method: 'POST',
  })
)

export const getPresOneWT = (id: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/get-one-custom_warm_stomach/${id}`,
      method: 'POST',
  })
)

export const getPresOneWY = (id: any): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/get-one-custom_warming/${id}`,
      method: 'POST',
  })
)

export const getNurseTenetList = (params: any): AxiosPromise<any> => (
  service({
      url: `/nurse-rule-manage/get-page`,
      method: 'POST',
      params
  })
)

export const getNurseSymptomList = (params: any): AxiosPromise<any> => (
  service({
      url: `/nurse-rule-manage/get-all-nurse-symptom`,
      method: 'POST',
      params
  })
)
export const saveNurseTenet = (params: any): AxiosPromise<any> => (
  service({
      url: `/nurse-rule-manage/save-or-update`,
      method: 'POST',
      params
  })
)

export const savePresRelation = (params: any): AxiosPromise<any> => (
  service({
      url: `/nurse-rule-manage/add-pres-relations/${params.ruleId}`,
      method: 'POST',
      params
  })
)

export const copyNursePres = (id: string|number): AxiosPromise<any> => (
  service({
      url: `/nurse-pres-manage/copy-pres/${id}`,
      method: 'POST',
  })
)








  
