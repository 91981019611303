
import { Watch, Component, Vue, Ref, PropSync, Prop } from 'vue-property-decorator';

import { nursePresWayList } from "@/api/nurse";

@Component
export default class RelationPresTransferWay extends Vue {
    @Prop({ type: Array, default: () => [] }) stashList !: Array<any>;
    @Prop({ default: true }) clearStashListFlag !: boolean;
    @Ref("transferRef") transferRef: any;

    stashPresWay = [];
    presWay = [];

    mounted() {
        this.getPresWay();
    }
    getPresWay() {
        nursePresWayList({ pageNum: 1, pageSize: 1000, presType: 1 }).then((res) => {
            console.log("nursePresWayList", res.data);
            res.data.list.forEach((item) => {
                item.presName = item.presName + `（${item.status == 1 ? "启用" : "禁用"}）`;
            });
            this.presWay = res.data.list;
        });
    };

    closeTransfer() {
        this.transferRef?.clearQuery("left");
        this.transferRef?.clearQuery("right");
        if (this.clearStashListFlag) this.clearStashList();
    };

    clearStashList() {
        this.stashPresWay = [];
    };

    getStashStoreInfoList() {
        return this.stashPresWay.map((item) => {
            const { id, storeName } = this.presWay.find((store) => store.id == item) || {};
            return {
                id,
                storeName
            };
        });
    };

    @Watch("stashPresWay")
    syncStashPresWay(list) {
        this.$emit("syncPresWay", [...list]);
    }

    @Watch("stashList")
    @Watch("presWay", { deep: true })
    handleStashList(list) {
        this.stashPresWay = [...this.stashList];
    }

    

}
