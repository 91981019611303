
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    InjectReactive
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";

import {
    cloneDeep
} from "lodash"

import {
    moxaOrderTemplateAcupointOptionApi,
    moxaOrderTemplateMoxaOptionApi,
} from "@/api/moxibustion";

import { updateNursePresBaseInfoAJ, getPresOneAJ } from "@/api/nurse";

import {
    Form
} from "element-ui";

const initRow = {
    acupoint: "",
    moxaFunc: "",
    time: undefined
}

@Component({
    components: {
        UploadImg
    }
})
export default class NursePresAddPersonal extends Vue {
    @Ref("formRef") formRef!: Form;
    @InjectReactive("addFormData") addFormData!: any;

    formData = {
        tempName: "",
        img: [],
        imgUrl: [],
        rules: {
            // imgUrl: {
            //     required: true,
            //     type: "array",
            //     message: "请选择穴位",
            //     trigger: ['blur', 'change']
            // },
            tempName: {
                required: true,
                message: "请输入模板名称",
                trigger: ['blur', 'change']
            },
            acupoint: {
                required: true,
                message: '请选择穴位',
                trigger: ['blur', 'change']
            },
            moxaFunc: {
                required: true,
                message: '请选择灸法',
                trigger: ['blur', 'change']
            },
            time: {
                required: true,
                message: '请输入时长',
                trigger: ['blur']
            },
        },
        tableData: [{
            acupoint: "",
            moxaFunc: "",
            time: undefined
        }],
    }

    xwOptions = [];
    jfOptions = [
        { value: 0, label: "回旋灸" },
        { value: 1, label: "雀啄灸" },
        { value: 2, label: "悬停灸" },
        { value: 3, label: "往复灸" }
    ];
    sjOptions = [];
    statusOptions = [{
        label: "已启用",
        value: 1
    }, {
        label: "已禁用",
        value: 0
    }]

    ajImageList = []

    mounted() {
        this.getOptions();
        this.getDetail();
    }

    getDetail() {
        if (this.addFormData.healthType == 1 && this.addFormData.id) {
            getPresOneAJ(this.addFormData.id).then((res) => {
                console.log("res", res)
                const { imgUrl = [], list = [] } = res.data;
                if (!list.length) return;
                this.formData.imgUrl = imgUrl.map((el) => ({ url: el }));
                this.formData.tableData = list.map((el) => ({
                    acupoint: el.acupoint,
                    moxaFunc: el.moxaFunc,
                    time: el.time
                }));
            })
        }
    }

    getOptions() {
        moxaOrderTemplateAcupointOptionApi({}).then((res) => {
            this.xwOptions = res.data || [];
            this.xwOptions?.forEach((op) => {
                op.value = op.code;
                op.label = op.name;
            })
        })
    }

    addRow() {
        console.log("add`")
        this.formData.tableData.push(cloneDeep(initRow));
        this.$nextTick(() => {
            const formTableEL = document.querySelector(".formTable");
            const tableWrapper = formTableEL?.querySelector(".el-table__body-wrapper");
            if (tableWrapper) {
                tableWrapper.scrollTop = tableWrapper.scrollHeight;
            }
        })
    }

    delRow(row, index) {
        if (this.formData.tableData?.length <= 1) return;
        this.formData.tableData.splice(index, 1);
    }

    acupointIdChange(row) {
        row.moxaFunc = "";
    }

    moxibustionIdChange(row) {
        this.getMoxaOption(row.acupoint);
    }

    getMoxaOption(acupoint_id) {
        moxaOrderTemplateMoxaOptionApi(acupoint_id).then((res) => {
            let options = res.data || [];
            options?.forEach((op) => {
                op.value = op.code;
                op.label = op.name;
            })
            this.jfOptions = cloneDeep(options);
        }).catch(() => {
            this.jfOptions = [];
        })
    }

    validateForm() {
        return this.formRef?.validate().then(() => {
            const params = {
                list: this.formData.tableData?.map(item => ({ ...item, customId: this.addFormData.id })),
                imgUrl: this.ajImageList,
            }
            updateNursePresBaseInfoAJ(params);
            return params;
        })
    }

    cancel() {
        this.formRef?.resetFields();
    }



    @Watch("formData.tableData", { deep: true, immediate: true })
    @Watch("xwOptions", { deep: true, immediate: true })
    getAjImageList() {
        console.log(this.formData.tableData)
        const _list = this.formData.tableData?.map(item => {
            const xw = this.xwOptions?.find(el => el.code == item.acupoint)
            return xw?.imgLink;
        })?.filter((el) => el);
        this.ajImageList = _list;
    }

}
