<template>
    <div class="content-box">
        <div class="content-header">
          <el-select class="input" v-model="queryForm.isEnable" clearable placeholder="启禁状态">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
            <el-input
                v-model="queryForm.name"
                maxlength="50"
                class="input"
                clearable
                placeholder="请输入品牌方名称"
            ></el-input>
            <el-input
                v-model="queryForm.storeName"
                maxlength="50"
                class="input"
                clearable
                placeholder="请输入门店名称"
            ></el-input>
            <el-input
                v-model="queryForm.phone"
                maxlength="50"
                class="input"
                clearable
                placeholder="请输入联系方式"
            ></el-input>
            <el-button type="plain" class="content-btn" @click="handleSearch">搜索</el-button>
            <el-button type="plain" class="content-btn-big" icon="el-icon-plus" @click="goMerchantAdd">新增品牌方</el-button>
        </div>
        <div class="table-list">
            <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
                v-loading="loading">
                <el-table-column prop="name" align="center" width="" label="品牌方名称">
                  <template #default="{row}">
                    <span @click="toStoreList(row)" class="a-span">{{row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="id" align="center" width="" label="关联门店数">
                    <template #default="{ row }">
                        <el-button @click="viewStores(row)" type="text">{{row.stores?.length}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="contact" align="center" width="" label="负责人" />
                <el-table-column prop="phone" align="center" width="" label="联系方式" />
                <el-table-column prop="autoRegisterUser" align="center" width="" label="自动注册会员">
                  <template #default="{row}">
                    {{row.autoRegisterUser ? "是" : "否"}}
                  </template>
                </el-table-column>
                <el-table-column prop="defaultImgStatus" align="center" width="" label="自定义用户头像">
                  <template #default="{row}">
                    {{row.defaultImgStatus ? "启用" : "禁用"}}
                  </template>
                </el-table-column>
                <el-table-column prop="allowUseQrcode" align="center" width="" label="使用养身帮产品">
                  <template #default="{row}">
                    {{row.allowUseQrcode ? "启用" : "禁用"}}
                  </template>
                </el-table-column>
                <el-table-column prop="useCustomPres" align="center" width="" label="自定义处方库">
                  <template #default="{row}">
                    {{row.useCustomPres ? "启用" : "禁用"}}
                  </template>
                </el-table-column>
                <el-table-column prop="configStatus" align="center" width="" label="小程序配置">
                    <template #default="{ row }">
                        {{row.configStatus ? "是" : "否"}}
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" width="" label="品牌方状态">
                  <template #default="{row}">
                    {{row.status ? "启用" : "禁用"}}
                  </template>
                </el-table-column>
                <el-table-column prop="createdBy" align="center" width="" label="创建人" />
                <el-table-column prop="createdTime" align="center" width="" label="创建时间" />
                <el-table-column prop="id" align="center" width="" label="操作" fixed="right">
                    <template #default="{row}">
                      <el-button type="text" @click="goMerchantActList(row)">操作</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>
        </div>
        <el-dialog :title="storeListInfo.title" :visible.sync="storeListInfo.visible" width="600px" :before-close="storeInfoDialogClose">
            <el-table :data="storeListInfo.data" style="width: 100%" :max-height="500" :header-cell-style="headerCellStyle" border>
                <el-table-column prop="storeId" align="center" width="" label="门店id" />
                <el-table-column prop="storeName" align="center" width="" label="门店名称" />
            </el-table>
        </el-dialog>
        <MiniSetting :dialogVisible.sync="miniSettingVisible" :brandInfo="currentBrandInfo" @syncList="getData" />
    </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { getMerchantList, queryStore, saveOrUpdateMerchant, changeMerchantStatus } from "@/api/prescriptionDb";

// 工具
import { debounce, cloneDeep, uniqWith } from "lodash";

import { ServiceStatusEnum as StatusEnum } from "@/enum/service.enum";

import MiniSetting from "./component/miniSetting.vue";

import UploadImg from "@/components/uploadImg.vue";

@Component({
  components: {
    MiniSetting,
    UploadImg
  }
})
export default class MerchantList extends Mixins(loading, tablePagination, Resize) {
  brandStatusEnum = StatusEnum

  queryForm = { // 查询参数
    name: "",
    phone: "",
    storeName: "",
    isEnable: "",
  }

  statusOptions = [{ label: "已启用", value: 1 }, { label: "已禁用", value: 0 }];

  tableData = [];
  itemTotal = 0;

  storeListInfo = {
    visible: false,
    title: "",
    data: [],
  }

  miniSettingVisible = false;
  currentBrandInfo = {};

  mounted() {
    this.windowResize(310)
    this.getData();
  }

  // form 的validate 方法
  validateStoreInfo(rule, value, callback) {
    if (this.storeInfo?.length) return callback();
    return callback(new Error("请选择关联门店")) 
  }

  getData() {
    this.showLoading();
    getMerchantList({
      ...this.queryForm,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    }).then(res => {
      this.hideLoading();
      res.data.list.forEach((el) => {
        el.stores?.forEach((s) => {
            s.id = s.storeId;
        })
      })
      this.tableData = res.data.list;
      this.itemTotal = res.data.total;
    }).finally(() => {
      this.hideLoading();
    });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }

  toStoreList(row) {
    this.$router.push({ path: "/storeList", query: { merchantId: row.id } })
  }

  // 查看绑定门店详情
  viewStores(row) {
    Object.assign(this.storeListInfo, { visible: true, title: row.name, data: row.stores })
  }

  storeInfoDialogClose() {
    Object.assign(this.storeListInfo, { visible: false, title: "", data: [] })
  }

  settingMiniConfig(row) {
    this.miniSettingVisible = true;
    this.currentBrandInfo = cloneDeep(row);
  }

  goMerchantAdd(row) {
    this.$router.push({
       path: "/merchantEdit",
    })
  }

  goMerchantActList(row) {
    this.$router.push({
       path: "/merchantAction",
       query: { merchantId: row.id, brandName: row.name }
    })
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/app";

.content-box {
  background: #edf0f9;
}

.content-header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dfe2e4;
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
  display: flex;
  align-items: center;
}

.input {
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 12px;
}
.content-btn {
  margin-left: 12px;
  border: 1px solid #0054ff;
}

.content-btn-big {
  margin-left: 40px;
  width: 300px;
  border: 1px dashed #0054ff;
}

.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 101px);
}

.dialogInput {
  width: 300px;
  height: 40px;
  border-radius: 4px;
}

.dialog-footer{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.store-saas-name{
  color: $primary;
  margin-left: 5px;
}
.tag {
  margin-right: 10px;
  height: auto;
  max-width: 100%;
  line-height: 20px;
  padding: 6px 10px;
  white-space: wrap;
  // span{
  //   white-space: normal;
  // }
}
.disable-btn {
  color: #30a16b;
}
.enable-btn {
  color: #ff6700;
}
.el-button--text{
  margin-left: 0!important;
}
</style>
<style>
.el-popover{
  text-align: center;
  min-width: 55px!important;
}
</style>
