import { service } from '@/ajax/request'

export const appBannerList = (params) => {
    return service({
        url: `/mall/mall-banner/list`,
        method: "POST",
        params
    })
}

export const createUpdateBanner = (params) => {
    return service({
        url: `/mall/mall-banner/createOrUpdate`,
        method: "POST",
        params
    })
}

export const getBannerStoreList = (params) => {
    return service({
        url: `/mall/mall-banner/view/code`,
        method: 'POST',
        params,
    })
}

