
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    InjectReactive
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";

import {
    Form
} from "element-ui";

import { updateNursePresBaseInfoWT, getPresOneWT } from "@/api/nurse";

@Component({
    components: {
        UploadImg
    }
})
export default class nursePresAddWt extends Vue {
    @Ref("formRef") formRef!: Form;
    @InjectReactive("addFormData") addFormData!: any;

    formData = {
        rules: {
            strength: {
                required: true,
                message: "请输入",
                trigger: ['blur', 'change']
            },
            infrared: {
                required: true,
                message: "请输入",
                trigger: ['blur', 'change']
            },
            foment: {
                required: true,
                message: "请输入",
                trigger: ['blur', 'change']
            }
        },
        tableData: [{
            strength: 3,
            infrared: 15,
            foment: 2
        }],
    }

    mounted() {
        this.getDetail();
    }

    getDetail() {
        if (this.addFormData.healthType == 5 && this.addFormData.id) {
            getPresOneWT(this.addFormData.id).then((res) => {
                // const data = res.data;
                // this.formData = { ...data };
                const record = res.data || { strength: 3, infrared: 15, foment: 2 };
                this.formData.tableData = [record];
            })
        }
    }

    validateForm() {
        return this.formRef?.validate().then(() => {
            const params = {
                customId: this.addFormData.id,
                ...this.formData.tableData?.[0],
            }
            updateNursePresBaseInfoWT(params)
            return this.formData;
        })
    }

}
