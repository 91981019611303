
import { Mixins, Component, ProvideReactive, Watch } from "vue-property-decorator";

import AppCompose from "./components/appCompose.vue";
import GroupType from "./components/groupType.vue"

@Component({
  inject: [],
  components: { AppCompose, GroupType }
})
export default class appComposeIndex extends Mixins() {
  @ProvideReactive() currentTab = "1";

  brandName = "";

  @Watch("$route", { immediate: true })
  routeChange() {
    this.currentTab = "1";
    const path = this.$route.path;
    if (["/appCompose", "/merchantAppCompose"].includes(path)) {
      this.brandName = <string>this.$route.query.brandName;
      // this.merchantId = this.$route.query.merchantId;
    }
  }
}

