
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    Prop,
    InjectReactive
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";

import {
    cloneDeep
} from "lodash"

import { updateNursePresBaseInfoJM, getPresOneJM } from "@/api/nurse";
import {
    Form
} from "element-ui";

const initRow = {
    acupointId: "",
    moxibustionId: "",
    time: undefined
}

@Component({
    components: {
        UploadImg
    }
})
export default class  NursePresAddJm extends Vue {
    @Ref("formRef") formRef!: Form;
    @Prop({ default: 11 }) customId !:string;
    @InjectReactive("addFormData") addFormData!: any;

    formData = {
        tempName: "",
        img: [],
        
        rules: {
            mode: {
                required: true,
                message: "请选择模式",
                trigger: ['blur', 'change']
            },
            strength: {
                required: true,
                message: "请输入强度",
                trigger: ['blur', 'change']
            },
            heat: {
                required: true,
                message: "请输入热度",
                trigger: ['blur', 'change']
            },
        },
        tableData: [
            { body: "背部", mode: 1, strength: "12", heat: "5" },
            { body: "腰部", mode: 1, strength: "12", heat: "5" },
            { body: "肩部", mode: 1, strength: "8", heat: "5" },
            { body: "腿部", mode: 1, strength: "8", heat: "6" },
        ],
    }

    msOptions = [
        { label: "高频", value: 1 },
        { label: "低频", value: 2 },
    ]

    mounted() {
        this.getDetail();
    }

    getDetail() {
        if (this.addFormData.healthType == 2 && this.addFormData.id) {
            getPresOneJM(this.addFormData.id).then((res) => {
                const list = res.data;
                if (!list?.length) return;
                this.formData.tableData = list.map((el) => ({
                    body: el.body,
                    mode: el.mode,
                    strength: el.strength,
                    heat: el.heat
                }));
            })
        }
    }

    validateForm() {
        return this.formRef?.validate().then(() => {
            const params = this.formData.tableData?.map(item => {
                return ({ ...item, customId: this.addFormData.id })
            })
            updateNursePresBaseInfoJM(params).then((res) => {

            })
            return this.formData;
        })
    }
}
