
import { Ref, Mixins, Component, InjectReactive, Watch } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";
import RichText from "@/components/rich-text.vue";
import { healthExamSuggestSave, healthExamSuggestList, healthExamSuggestNames } from "@/api/healthEvaluation";

import { Form } from "element-ui";

@Component({
  components: {ETable,RichText}
})
export default class HealthEvaluationReport extends Mixins(loading, tablePagination, Resize) {
    @InjectReactive() currentTab !: number;
    @Ref("ruleForm") ruleFormRef !: Form;
    healthNameOrShowName = "";

    // loading = false;
    itemTotal = 0;
    tableData = [];

    columns = [
        { label: "ID", prop: "id" },
        { label: "测评名称", prop: "examName" },
        { label: "外显名称", prop: "showName" },
        { label: "风险等级", prop: "riskLevelDesc" },
        { label: "检测结果", prop: "detetcionResult" },
        { label: "症状介绍", prop: "symptom" },
        { label: "形成原因", prop: "reason" },
        { label: "膳食运动", prop: "homeHealth" },
        { label: "启禁状态", prop: "statusDesc" },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "操作", prop: "action" },
    ]

    examineNameOptions = [];
    riskLevelOptions = [
        { label: "健康", value: 1 },
        { label: "亚健康", value: 2 },
        { label: "疑似风险", value: 3 },
    ]

    dialogVisible = false;
    form = {
        id: "",
        healthId: "",
        riskLevel: "",
        showName: "",
        status: 1,
        detetcionResult: "",
        symptom: "",
        reason: "",
        homeHealth: "",
        preventDisease: "",
    };
    rules = {
        healthId: [{ required: true, message: "请选择测评名称" }],
        riskLevel: [{ required: true, message: "请选择风险等级" }],
        status: [{ required: true, message: "请选择状态" }],
        detetcionResult: [{ required: true, message: "请输入检测结果" }],
        symptom: [{ required: true, message: "请输入症状介绍" }],
        reason: [{ required: true, message: "请输入形成原因" }],
    }

    mounted() {
      this.windowResize(320);
    }

    getOptions() {
        healthExamSuggestNames().then((res) => {
            this.examineNameOptions = res.data;
        })
    }

    healthIdChange($event) {
        const cp = this.examineNameOptions.find(op => op.id == $event);
        this.form.showName = cp.showName;
    }

    searchData() {
        this.resetCurrent();
        this.getData();
    }

    @Watch("currentTab")
    getData() {
      this.getOptions();
      if (this.currentTab != 2) return;
      const params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          healthNameOrShowName: this.healthNameOrShowName,
      }
      this.showLoading();
      healthExamSuggestList(params).then((res) => {
        this.tableData = res.data.list;
        this.tableData.forEach((el) => {
            el.riskLevelDesc = this.riskLevelOptions.find((rl => rl.value == el.riskLevel)).label
            el.statusDesc = el.status ? "启用" : "禁用";
        })
        this.itemTotal = res.data.total;
      }).finally(() => {
        this.hideLoading();
      })
    }

    add() {
        this.dialogVisible = true;
    }

    edit(row) {
        this.dialogVisible = true;
        this.$nextTick(() => {
            Object.assign(this.form, row);
        })
    }

    save() {
        this.ruleFormRef.validate().then(() => {
            // @ts-ignore
            const { modifiedTime, modifiedBy, ...params } = this.form;
            healthExamSuggestSave({ ...params, isEdit: this.form.id ? 1 : 0 }).then((res) => {
                this.$message.success("操作成功");
                this.getData();
                this.handleClose();
            })
        })
    }

    handleClose() {
        this.ruleFormRef.resetFields();
        this.dialogVisible = false;
        this.form.id = "";
        this.form.showName = "";
        this.form.riskLevel = "";
    }

}

