import { service } from '@/ajax/request'

export const getSymptomList = (params: any) => (
    service({
        url: '/nurse-symptom/get-list',
        method: 'POST',
        params,
    })
)

export const editSymptom = (params: any) => (
    service({
        url: '/nurse-symptom/create-or-update-symptom',
        method: 'POST',
        params,
    })
)

export const getDetectionResultById = (diagnosesId: any) => (
    service({
        url: `/nurse-symptom/get-one-relations/${diagnosesId}`,
        method: 'POST',
    })
)

export const getIntelligentList = (params = {}) => (
    service({
        url: `/nurse-symptom/getIntelligentInfo`,
        method: 'POST',
        params
    })
)

export const createOrUpdateIntelligent = (params = {}) => (
    service({
        url: `/nurse-symptom/create-or-update-Intelligent`,
        method: 'POST',
        params
    })
)

export const createNurseSymptomRelations = (params: any) => (
    service({
        url: `/nurse-symptom/create-or-update-relations`,
        method: 'POST',
        params,
    })
)