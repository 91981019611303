
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { adminRoleUpdate, adminUpdate, adminRegister } from "@/api/user";
import { allRole, adminRoleById } from "@/api/admin";
import { User } from "@/types/api/user";
// 工具
import copy from "@/utils/deepCopy";
import debounce from "@/utils/debounce";
// 组件
import Header from "./components/Header.vue";
import Table from "./components/Table.vue";
import Dialog from "./components/Dialog.vue";

@Component({
  components: {
    Header,
    Table,
    Dialog,
  },
})
export default class HelloWorld extends Vue {
  @Ref("dialog") dialogRef:Dialog;

  keyword: string = ""; // 搜索关键字
  phone: string = "";
  dialogVisible: boolean = false; // 是否显示对话框
  dialogTitle: string = "分配角色"; // 对话框类型 '分配角色' '添加' '编辑'
  // 初始化表单
  initForm: any = {
    createTime: "",
    email: "",
    icon: "",
    id: 0,
    loginTime: "",
    nickName: "",
    note: "",
    password: "",
    status: true,
    username: "",
    ids: [],
    staffTypes: [], mini: {}, user: {}
  };
  // 表单
  form: any = this.initForm;

  /**
   * 生命周期
   */
  mounted() {
    let table: any = this.$refs.table;
    table.getData();
  }
  /**
   * 监听
   */
  @Watch("keyword")
  @Watch("phone")
  searchValue() {
    let ref: any = this.$refs.table;
    debounce(() => ref.getData(true));
  }

  /**
   * methods
   */
  // 确认搜索
  confirmSearch() {
    let ref: any = this.$refs.table;
    ref.getData();
  }
  // 添加
  handleAdd() {
    this.form = copy({...this.initForm, staffTypes: [], mini: {}, user: {} });
    this.dialogVisible = true;
    this.dialogTitle = "添加";
  }
  // 确认提交
  async confirm(formData?: any) {
    formData = copy(formData);
    let promi: any;
    if (
      formData.username.trim().length == 0 ||
      formData.nickName.trim().length == 0 ||
      formData.password.trim().length == 0
    ) {
      this.$message.error("用户信息不能为空");
      return;
    }
    formData.status = formData.status ? 1 : 0;
    switch (this.dialogTitle) {
      case "分配角色":
        promi = await adminRoleUpdate({
          roleIds: formData.ids.toString(),
          adminId: formData.id,
        });
        break;
      case "编辑":
        promi = await adminUpdate(formData);
        break;
      case "添加":
        promi = await adminRegister(formData);

      default:
        break;
    }
    if (promi.code == 200) {
      this.$message.success(`${this.dialogTitle} 成功`);
      this.dialogRef?.formRef?.resetFields()
      this.dialogVisible = false;
      this.form = copy(this.initForm);
      let ref: any = this.$refs.table;
      ref.getData();
    }
  }
  // 修改
  handleClick(row: any, dialogTitle: string, conf: boolean) {
    this.form = copy({...row, mini: row.mini || {}, user: row.user || {} });
    this.dialogTitle = dialogTitle;
    if (conf) {
      this.confirm(this.form);
    } else {
      this.dialogVisible = true;
    }
  }
}
