import { service } from '@/ajax/request'

export const appUpdateList = (params: any) => (
    service({
        url: '/app/appSystemEdition/appEditPage',
        method: 'POST',
        params,
    })
)

export const appUpdateDelete = (params: any) => (
    service({
        url: '/app/appSystemEdition/deleteAppEdition',
        method: 'POST',
        params,
    })
)

export const appUpdateSave = (params: any) => (
    service({
        url: '/app/appSystemEdition/saveOrUpdateAppEdit',
        method: 'POST',
        params,
    })
)

export const getAppUpdateById = (params: any) => (
    service({
        url: '/app/appSystemEdition/selectInfoById',
        method: 'POST',
        params,
    })
)