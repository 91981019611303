
import { Form } from "element-ui";
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    Prop,
    InjectReactive
} from "vue-property-decorator";

import { updateNursePresBaseInfoXZ, getPresOneXZ } from "@/api/nurse";
import { PresMusic } from "@/enum/nurse.enum";


@Component({
    components: {
    }
})
export default class NursePresAddXz extends Vue {
    @Ref("formRef") formRef!: Form;
    @Prop() customId !:string;
    @InjectReactive("addFormData") addFormData!: any;

    formData = {
        temperature: 40,
        steamIntensity: 3,
        music: "随机",
        lightwaveTherapy: 1,
    }

    rules = {
        temperature:{ required: true, message: "请输入建议温度", trigger: ['blur', 'change'] },
        steamIntensity: { required: true, message: "请选择蒸汽强度", trigger: ['blur', 'change'] },
        music: { required: true, message: "请选择音乐疗法", trigger: ['blur', 'change'] },
        lightwaveTherapy: { required: true, message: "请选择光波疗法", trigger: ['blur', 'change'] },
    }

    yylfOptions = [{label: "随机", value: 0}]
    gblfOptions = [
        { label: "蓝光", value: 1, },
        { label: "紫光", value: 2, },
        { label: "红光", value: 3, },
        { label: "黄光", value: 4, },
        { label: "绿光", value: 5, },
        { label: "青光", value: 6, },
    ]

    mounted() {
        // console.log("PresMusic", Object.keys(PresMusic))
        const keyList = Object.keys(PresMusic);
        // console.log(keyList?.slice(keyList?.length / 2 + 1, keyList?.length))
        this.yylfOptions = [
            {label: "随机", value: 0},
            ...keyList?.slice((keyList?.length/2 + 1), keyList?.length)?.map((el, index) => {
                return ({ label: el, value: index + 1 })
            }),
        ]
        this.getDetail();
    }

    getDetail() {
        if (this.addFormData.healthType == 3 && this.addFormData.id) {
            getPresOneXZ(this.addFormData.id).then((res) => {
                const data = res.data;
                this.formData = { ...data, lightwaveTherapy: parseInt(data.lightwaveTherapy) };
            })
        }
    }

    validateForm() {
        return this.formRef?.validate().then(() => {
            let { music } = this.formData;
            if (music == "随机") {
                const num = Math.floor(Math.random() * 52);
                music = this.yylfOptions[num].label;
            }
            const params = {
                customId: this.addFormData.id,
                ...this.formData,
                music,
            }
            updateNursePresBaseInfoXZ(params)
            return this.formData;
        })
    }
}
