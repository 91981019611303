import { service } from '@/ajax/request'

export const getIntelligentList = (params) => (
  service({
    url: '/intelligentDialectics/intelligentInfoPage',
    method: 'POST',
    params
  })
)

export const getIntelligentType = () => (
  service({
    url: '/intelligentDialectics/getIntelligentType',
    method: 'POST',
  })
)

export const saveOrUpdateIntelligent = (params: any) => (
  service({
    url: '/intelligentDialectics/saveOrUpdateIntelligent',
    method: 'POST',
    params
  })
)

