
import { Watch, Emit, Prop, Component, Vue, InjectReactive } from "vue-property-decorator";
import PrescriptionOne from './prescriptionOne.vue';
import { prescriptionSymptom } from "@/api/prescriptionDb";

@Component({
    components: {
        PrescriptionOne
    }
})
export default class Height extends Vue {
    @Prop() readonly currentTab: number | string
    @InjectReactive() pageCurrentTab!: string|number;
    @InjectReactive() merchantId!: string|number;

    activeName = "5"
    list = [];
    total = 0;
    pageLoading = false;

    collapseChange(id) {
        if (!id) return;
        this.$nextTick(() => {
            const index = this.list.findIndex((el) => el.id == id);
            const ref = this.$refs.prescriptionOneRef[index];
            ref.prescriptionLoadPres(id, this.merchantId);
        })
    }
    @Emit("syncTotal")
    syncTotal() {
        return this.total;
    }

    @Watch("currentTab", { immediate: true })
    prescriptionSymptom(value, queryParams) {
        if (value != 5) return; 
        const params = {
            libCode: "10008",
            symptomName: "",
            pageNum: 1,
            pageSize: 20,
        }
        if (queryParams) {
            Object.assign(params, queryParams)
        }
        this.pageLoading = true;
        prescriptionSymptom(params).then((res) => {
            this.list = res.data.list || [];
            this.total = res.data.total;
            this.syncTotal();
        }).finally(() => {
            this.pageLoading = false;
        })
    }

}
