
import {
    Watch,
    Mixins,
    Component,
    Vue,
    InjectReactive
} from 'vue-property-decorator'
import Resize from "@/mixins/resize";
import TablePagination from "@/mixins/tablePagination";
import Loading from "@/mixins/loading";

import ETable from "@/components/ETable.vue";

import { nursePresWayList, copyNursePres, getPresOneAJ, getPresOneJM, getPresOneXZ, getPresOneWY, updateNursePresBaseInfoWT, getPresOneWT,
    updateNursePresBaseInfoAJ, updateNursePresBaseInfoJM, updateNursePresBaseInfoXZ, updateNursePresBaseInfoWY } from "@/api/nurse";

import { formatTextarea } from "@/utils/common";

@Component({
    // inject: [],
    components: { ETable }
})
export default class NursePresWay extends Mixins(TablePagination,Resize,Loading) {

    formatTextarea = formatTextarea

    queryForm = {
        name: "",
        type: "",
        presType: 1,
        suitableFor: ""
    };

    typeOptions = [
        { label: "艾灸",value: 1 },
        { label: "筋膜松解",value: 2 },
        { label: "中药熏蒸",value: 3 },
        { label: "温阳调养",value: 4 },
        { label: "温通调养",value: 5 },
    ]

    suitableForOptions = [
        { label: "通用",value: 0 },
        { label: "男",value: 1 },
        { label: "女",value: 2 },
    ]

    columns = [{
        label: "ID",
        prop: "id",
        width: 70,
        align: "center"
    },
    {
        label: "处方名称",
        prop: "presName",
        minWidth: 150,
        align: "center"
    },
    {
        label: "智养类型",
        prop: "",
        width: 120,
        align: "center",
        formatter:(row) => this.typeOptions?.find(el => el.value == row.healthType)?.label
    },
    {
        label: "适用人群",
        prop: "",
        width: 120,
        align: "center",
        formatter:(row) => this.suitableForOptions?.find(el => el.value == row.suitableFor)?.label
    },
    {
        label: "适用节气",
        prop: "termsList",
        minWidth: 150,
        align: "center"
    },
    {
        label: "时长（分钟）",
        prop: "duration",
        minWidth: 180,
        align: "center"
    },
    {
        label: "功效",
        prop: "effect",
        minWidth: 200,
        align: "center"
    },
    {
        label: "调养周期",
        prop: "period",
        minWidth: 180,
        align: "center"
    },
    {
        label: "方法",
        prop: "solution",
        minWidth: 180,
        align: "center"
    },
    {
        label: "状态",
        prop: "status",
        minWidth: 180,
        align: "center"
    },
    {
        label: "操作人",
        prop: "modifiedBy",
        minWidth: 150,
        align: "center"
    },
    {
        label: "最后操作时间",
        prop: "modifiedTime",
        minWidth: 180,
        align: "center"
    },
    {
        label: "操作",
        prop: "action",
        fixed: "right",
        width: 100,
        align: "center"
    },
    ]
    tableData = [];

    mounted() {
        this.windowResize();
        this.getData();
    }

    getData() {
        this.showLoading();
        nursePresWayList({
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }).then(res => {
            this.hideLoading();
            res.data.list.forEach((el) => {
                el.stores?.forEach((s) => {
                    s.id = s.storeId;
                })
            })
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }

    add() { 
        this.$router.push({
            path: "/nurse-pres-add"
        })
    }

    edit(row) {
        this.$router.push({
            path: "/nurse-pres-add",
            query: { id: row.id }
        })
    }

    copy(row) {
        const { id, healthType } = row;
        copyNursePres(id).then((res) => {
            const _id = res.data;
            switch (healthType){
                case 1:
                    this.getAjDetail(id, _id);
                    break;
                case 2:
                    this.getJmDetail(id, _id);
                    break;
                case 3:
                    this.getXzDetail(id, _id);
                    break;
                case 4:
                    this.getWyDetail(id, _id);
                    break;
                case 5:
                    this.getWtDetail(id, _id);
                    break;
            }
            this.getAjDetail(id, _id);
            this.$message.success("操作成功");
            this.getData();
        })
    }

    getAjDetail(id, _id) {
        getPresOneAJ(id).then((res) => {
            const { imgUrl = [], list = [] } = res.data;
            if (!list.length) return;
            list?.forEach((el) => { el.customId = _id })
            const params = { list, imgUrl }
            updateNursePresBaseInfoAJ(params);
        })
    }

    getJmDetail(id, _id) {
        getPresOneJM(id).then((res) => {
            const list = res.data;
            if (!list?.length) return;
            list?.forEach((el) => { el.customId = _id })
            const params = list;
            updateNursePresBaseInfoJM(params);
        })
    }

    getXzDetail(id, _id) {
        getPresOneXZ(id).then((res) => {
            const params = { ...res.data, customId: _id }
            updateNursePresBaseInfoXZ(params)
        })
    }

    getWyDetail(id, _id) {
        getPresOneWY(id).then((res) => {
            const params = { ...res.data, customId: _id }
            updateNursePresBaseInfoWY(params);
        })
    }

    getWtDetail(id, _id) {
        getPresOneWT(id).then((res) => {
            const params = { ...res.data, customId: _id }
            updateNursePresBaseInfoWT(params);
        })
    }

}
