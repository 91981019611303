
import {
    Mixins,
    Component,
} from "vue-property-decorator";

import Resize from "@/mixins/resize";
import TablePagination from "@/mixins/tablePagination";
import Loading from "@/mixins/loading";

import { getNurseTenetList } from "@/api/nurse";

import ETable from "@/components/ETable.vue";

import NurseTenetAdd from "./components/tenetAdd.vue";
import RelationPres from "./components/relationPres.vue";

import { formatTextarea } from "@/utils/common";

import { cloneDeep } from "lodash";

@Component({
    components: {
        ETable, NurseTenetAdd, RelationPres
    }
})
export default class PresAddHome extends Mixins(Resize, Loading, TablePagination) {

    formatTextarea = formatTextarea;

    queryForm = {
        name: "",
        symptomName: "",
    };

    columns = [
        {
            label: "ID",
            prop: "id",
            width: 70,
            align: "center"
        },
        {
            label: "调养原则",
            prop: "nurseName",
            minWidth: 150,
            align: "center"
        },
        {
            label: "调养方向",
            prop: "ruleDirection",
            width: 150,
            align: "center"
        },
        {
            label: "关联症状",
            prop: "symptomListDesc",
            width: 150,
            align: "center"
        },
        {
            label: "关联处方",
            prop: "presList",
            minWidth: 250,
            align: "center"
        },
        {
            label: "前导原则",
            prop: "preRules",
            width: 250,
            align: "center"
        },
        {
            label: "互斥原则",
            prop: "mutexRules",
            width: 250,
            align: "center"
        },
        {
            label: "原则描述",
            prop: "ruleDesc",
            width: 250,
            align: "center"
        },
        {
            label: "操作人",
            prop: "modifiedBy",
            minWidth: 150,
            align: "center"
        },
        {
            label: "最后操作时间",
            prop: "modifiedTime",
            minWidth: 180,
            align: "center"
        },
        {
            label: "操作",
            prop: "action",
            fixed: "right",
            width: 140,
            align: "center"
        },
    ]
    tableData = [{
        id: 1
    }];


    visible = false

    formData = {
        symptomRelationId: "",
        status: true
    } as any

    relationPresVisible = false
    relationPresFormData = {} as any

    mounted() {
        this.windowResize();
        this.getData();
    }

    getData() {
        this.showLoading();
        getNurseTenetList({
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }).then(res => {
            this.hideLoading();
            res.data.list.forEach((el) => {
                console.log(el.symptomList?.map(s => s.name))
                el.symptomListDesc = el.symptomList?.map(s => s.name).join("、");
            })
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }

    add() {
        this.visible = true;
        this.formData = {
            symptomRelationId: "",
            status: true
        }
    }


    edit(row) {
        this.visible = true;
        this.formData = cloneDeep(row);
        this.formData.symptomList = row.symptomList.map(item => item.id);;
        this.formData.status = Boolean(row.status);
        this.formData.preRules = this.formData.preRules?.map((el) => el.id)
        this.formData.mutexRules = this.formData.mutexRules?.map((el) => el.id)
    }

    relationPres(row) {
        this.relationPresVisible = true;
        this.relationPresFormData = cloneDeep(row);
        this.relationPresFormData.presList = row.presList?.map((el) => el)
    }

}
