
import {
    Watch,
    Mixins,
    Component,
    Vue,
    InjectReactive
} from 'vue-property-decorator'
import Resize from "@/mixins/resize";
import tablePagination from "@/mixins/tablePagination";
import Loading from "@/mixins/loading";

import ETable from "@/components/ETable.vue";

import {
    nursePresWayList, copyNursePres
} from "@/api/nurse";

import { formatTextarea } from "@/utils/common";

@Component({
    components: {
        ETable
    }
})
export default class NursePresHome extends Mixins(Loading, tablePagination, Resize) {
    @InjectReactive() currentTab;

    formatTextarea = formatTextarea
    
    queryForm = {
        name: "",
        presType: 3,
        custom: ""
    };

    columns = [{
            label: "ID",
            prop: "id",
            width: 70,
            align: "center"
        },
        {
            label: "处方名称",
            prop: "presName",
            minWidth: 150,
            align: "center"
        },
        {
            label: "介绍",
            prop: "instruction",
            width: 150,
            align: "center"
        },
        {
            label: "状态",
            prop: "status",
            width: 100,
            align: "center"
        },
        {
            label: "操作人",
            prop: "modifiedBy",
            minWidth: 150,
            align: "center"
        },
        {
            label: "最后操作时间",
            prop: "modifiedTime",
            minWidth: 180,
            align: "center"
        },
        {
            label: "操作",
            prop: "action",
            fixed: "right",
            width: 200,
            align: "center"
        },
    ]
    tableData = [{
        id: 1
    }];

    mounted() {
        this.windowResize();
        this.getData();
    }

    getData() {
        this.showLoading();
        nursePresWayList({
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }).then(res => {
            this.hideLoading();
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        });
    }

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }

    add() { 
        this.$router.push({
            path: "/nurse-pres-add-sport"
        })
    }

    edit(row) {
        this.$router.push({
            path: "/nurse-pres-add-sport",
            query: { id: row.id }
        })
    }

    copy(row) {
        const { id } = row;
        copyNursePres(id).then(() => {
            this.$message.success("操作成功");
            this.getData();
        })
    }

    @Watch("currentTab", { immediate: true })
    currentTabChange() {
        if(this.currentTab == "2") {
            this.windowResize();
        }
        
    }
}
