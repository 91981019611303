
import { Mixins, Component, Watch, Ref } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import { Form } from "element-ui/types";
import ETable from '@/components/ETable.vue';
import UploadImg from "@/components/uploadImg.vue";

import { getSolarTermsList, setCurrentSolarTerms, editSolarTerms } from "@/api/solarTerms";

@Component({ components: { ETable, UploadImg } })
export default class solarTermsList extends Mixins(tablePagination, loading, Resize) {
    @Ref("validateForm") validateForm: Form;

    queryForm = {
        name: "",
    }

    columns = [
        { prop: "name", label: "节气名称" },
        { prop: "season", label: "季节" },
        { prop: "imgUrl", label: "图片" },
        { prop: "instruction", label: "介绍说明" },
        { prop: "modifiedBy", label: "操作人" },
        { prop: "modifiedTime", label: "最后操作时间" },
        { prop: "action", label: "操作" },
    ];

    tableData = [];

    visible = false;

    formData = {
        name: "",
        imgUrl: [],
        instruction: "",
    };

    formRules = {
        imgUrl: [{ required: true, message: "请上传图片", trigger: "blur" }],
        instruction: [{ required: true, message: "请输入介绍说明", trigger: "blur" }],
    }

    mounted() {
        this.getData();
        this.windowResize();
    }

    getData() {
        this.showLoading();
        getSolarTermsList(this.queryForm).then((res) => {
            this.tableData = res.data;
        }).finally(() => {
            this.hideLoading();
        });
    }

    handleSearch() {
        this.getData();
    }

    handleEdit(row) {
        const { imgUrl } = row;
        this.formData = {
            ...row,
            imgUrl: imgUrl ? [{url:imgUrl}] : [],
        };
        this.visible = true;
    }

    handleSet(row) {
        this.$confirm(`是否将【${row.name}】设置为当前节气？`, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
        }).then(() => {
            this.showLoading();
            setCurrentSolarTerms(row.id).then((res) => {
                this.$message.success("设置成功");
                this.getData();
            }).finally(() => {
                this.hideLoading();
            })
        })
    }

    dialogClose() {
        this.validateForm?.resetFields();
        this.visible = false;
    }

    saveAppUpdate() {
        this.validateForm?.validate().then((valid) => {
            if (valid) {
                const params = {
                    ...this.formData,
                    imgUrl: this.formData.imgUrl[0].url,
                }
                this.showLoading();
                editSolarTerms(params).then((res) => {
                    this.$message.success("保存成功");
                    this.dialogClose();
                    this.getData();
                }).finally(() => {
                    this.hideLoading();
                })
            }
        })
    }
}
