
import { PropSync, Prop, Component, Vue, Ref } from "vue-property-decorator";
import { ElUpload } from "element-ui/types/upload";

import store from '@/store/index';


@Component
export default class UploadExcel extends Vue {
    @Ref("uploadExcelRef") uploadExcelRef: ElUpload;
    @PropSync("fileList", { default: () => ([]) })
    tempFileList!: any;
    @Prop({ default: 0.5 }) size;
    @Prop({ default: () => ([]) }) fileTypeList;
    @Prop() limit;
    @Prop({ default: `` }) uploadUrl;

    dialogVisible = false;
    dialogImageUrl = "";

    get requestHeader() {
        return {
            Authorization: store.getters.token
        }
    }

    handleSuccess(res, file) {
        this.$emit("syncProductList", res.data)
    }

    beforeUpload(file) {
        const isExcel = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type);
        const isLtM = this.size ? (file.size / 1024 / 1024) <= this.size : true;
        if (!isExcel) {
            this.$message.error('仅支持Excel文件格式!');
        }
        if (!isLtM) {
            this.$message.error(`文件大小不能超过${this.size}M!`);
        }
        return isExcel && isLtM;
    }
    handleRemove(file, fileList) {
        this.tempFileList = fileList;
    }

    fileChange(file, fileList) {
        console.log("-------------------")
        this.tempFileList = fileList;
    }
}

