
import { Component, Provide, Vue, Watch, Mixins, InjectReactive, ProvideReactive } from "vue-property-decorator";
// api
import { Userinfo } from "@/types/store";
import { Menu, MenuChild, EditableTabs } from "@/types/views";
import { loginOut, adminInfo } from "@/api/login";
// 组件
import Submenu from "./components/Submenu.vue";
import Content from "./components/Content.vue";
// 工具
import print from "@/utils/print";
import copy from "@/utils/deepCopy";

import { routes } from "@/router/index";

@Component({
  inject: [],
  components: {
    Submenu,
    Content,
  },
})
export default class Home extends Vue {
  @ProvideReactive() HomeInstance = this;

  /**
   * data
   */
  isCollapse: boolean = false;
  editableTabsValue: string = "home";
  editableTabs: Array<EditableTabs> = [];
  descripbeName: string = "";

  /**
   * 生命周期
   */
  mounted() {
    this.HomeInstance = this;
    adminInfo().then((res) => {
      this.$store.dispatch("setUserinfo", res.data);
    });
  }

  /**
   * computed计算
   */
  get menus(): any {
    return this.$store.getters.userinfo.menus;
  }

  get activeMenu(): string {
    let index = this.$route.path.replace("/", "");
    this.editableTabsValue = index;
    // README: 尽量不要在get 里面做set 操作，半天没找到;
    this.addTab(index,this.$route.fullPath);
    return index;
  }

  /**
   * watch监听
   */
  @Watch("$route", { immediate: true })
  routechange(to: any, from: any) {
    // 查看是否登录
  }

  /**
   * methods方法
   */
  // 添加tab
  addTab(name: string,fullPath:string) {
    let tit = "";
    this.menus.map((item: Menu): void => {
      if (item.children && item.children.length > 0) {
        item.children.map((e: MenuChild): void => {
          if (e.name == name) {
            tit = e.title;
          }
        });
      } else {
          if (item.name == name) {
            tit = item.title;
          }
      }
    });
    // 这里会有个情况就是路由接口还没加载好
    if (!tit) {
      const list = routes?.find(el => el.path == "/layout")?.children;
      tit = list.find((el) => el.path.replace("/", "") == name)?.name;
    }
    this.descripbeName = tit;

    if (this.editableTabs.find((e) => e.name == name)){
        this.editableTabs.forEach((e)=>{
            if(e.name == name){
              e.fullPath = fullPath;
            }
        });
    }else{
         this.editableTabs.push({
          title: tit,
          name: name,
          fullPath:fullPath
        });
    }

  }
  // 选中tab
  tabClick(tab: any): void {
    this.editableTabsValue = tab.name;
    this.descripbeName = tab.label;
    this.$router.replace({
      path:tab.$attrs['path']
    });
  }
  // 删除tab
  tabRemove(targetName: string) {
    let tabs = this.editableTabs;
    let activeName = this.editableTabsValue;
    let activePath = '';
    if (activeName === targetName) {
      tabs.forEach((tab: any, index: number) => {
        if (tab.name === targetName) {
          let nextTab = tabs[index + 1] || tabs[index - 1];
          if (nextTab) {
            //  选中上一个tab
            activeName = nextTab.name;
            activePath = nextTab.fullPath
          }
        }
      });
    }
    //  回到上一个tab
    this.editableTabsValue = activeName;
    this.editableTabs = this.editableTabs.filter((e) => e.name != targetName);
    let temIndex = this.editableTabs.findIndex(
      (e: EditableTabs) => e.name == activeName
    );
    temIndex > 0
      ? (this.descripbeName = this.editableTabs[temIndex].title)
      : null;
    // 至少留一个tab
    activeName == targetName ? null : this.$router.replace({
      path:activePath
    });
  }
  // 退出登录
  handleCommand(i: any) {
    if (parseInt(i) == 1) {
      this.$confirm("是否要退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        loginOut().then((res) => {
          localStorage.removeItem("yysj_token");
          this.$store.dispatch("setToken", "");
          this.$router.replace("login");
        });
        this.$router.replace("login");
      });
    } else {
      this.$confirm("修改密码，请联系客服", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {})
        .catch((err) => {});
    }
  }
  changeTabs(val: string) {
    this.editableTabsValue = val;
  }
}
