
import {
    Watch, Mixins, Component, Vue, InjectReactive, PropSync, Prop
} from 'vue-property-decorator'

import { getProductCategoryList, getProductList, saveOrUpdateMallProduct, getProductName } from "@/api/prescriptionDb";

import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { ProductSaleStatusDescEnum, ProductSaleStatusDescType } from "@/enum/product.enum";


import ETable from '@/components/ETable.vue';

@Component({
    components: { ETable }
})
export default class MallProductList extends Mixins(loading, tablePagination, Resize) {
    @PropSync("visible", { default: false }) relatedDialogVisible !: boolean;
    @Prop() categoryProductId !: number

    columns = [
        { label: "商品ID", prop: "spu", minWidth: "120" },
        { label: "商品分类", prop: "", minWidth: "120", formatter: (row: any) => row.category?.name },
        { label: "商品名称", prop: "name", minWidth: "120" },
        { label: "上下架状态", prop: "", minWidth: "100", formatter: (row: any) => this.getSellStatusDesc(row.sellStatus) },
        { label: "操作", prop: "action", minWidth: "100" },
    ]

    dataSource = []
    itemTotal = 0;
    selectedList = {
        name: "",
        spu: "",
    };

    queryForm = {
        categoryId: "",
        name: "",
        spu: "",
    };

    get disabledTag() {
        return !!this.selectedList.name;
    };
    id = 0;

    mounted() {
        this.initProductCategoryList();
    }

    relatedDialogClose() {
        this.relatedDialogVisible = false;
        this.queryForm = {
            categoryId: "",
            name: "",
            spu: "",
        };
        this.handleRemove();
    }

    handleRemove() {
        this.selectedList = {
            name: "",
            spu: "",
        };
    }

    productCategoryOptions = [];
    initProductCategoryList() {
        getProductCategoryList({ pageSize: 1000, pageNum: 1 }).then((res) => {
            this.productCategoryOptions = res.data.list;
        })
    }

    searchHandle(queryForm) {
        getProductList({ ...queryForm, pageNum: this.currentPage, pageSize: this.pageSize }).then(res => {
            this.dataSource = res.data.list;
            this.itemTotal = res.data.total;
        })
    }

    handleAdd(row) {
        this.selectedList = row;
    }
    RelatedProductConfirm() {
        const params = {
            categoryProductId: this.categoryProductId,
            name: this.selectedList?.name,
            spu: this.selectedList?.spu,
        }
        saveOrUpdateMallProduct(params).then(res => {
            this.relatedDialogVisible = false;
            this.$emit("refreshProductList")
            this.handleRemove();
        })
    }

    getSellStatusDesc(status: ProductSaleStatusDescType) {
        return ProductSaleStatusDescEnum[status];
    }

    @Watch('relatedDialogVisible')
    getProductName() {
        if (!this.relatedDialogVisible) return;
        this.pageSize = 10;
        this.currentPage = 1;
        getProductName({ categoryProductId: this.categoryProductId }).then((res) => {
            if (res.data?.spu) {
                this.selectedList = res.data;
            }
        })
    }


    @Watch('categoryProductId')
    @Watch('relatedDialogVisible')
    getData() {
        if (!this.relatedDialogVisible) return;
        this.searchHandle(this.queryForm);
    }
}

