
  import { Watch,Mixins,Component, Vue, ProvideReactive } from "vue-property-decorator";
  import { cloneDeep } from "lodash";

  import NursePresWay from "./components/nursePresWay.vue";
  import NursePresHome from "./components/nursePresHome.vue";
  import NursePresSport from "./components/nursePresSport.vue";
  
  @Component({
      inject: [],
      components: {
      }
  })
  export default class NursePresList extends Vue {
    @ProvideReactive() currentTab = "0";
    nursePresOptions = [
        { label: "调养方法", value: "0", component: NursePresWay },
        { label: "居家调养", value: "1", component: NursePresHome },
        { label: "运动膳食", value: "2", component: NursePresSport },
    ]

    handleClick() {}
  }
  