
import UploadImg from "@/components/uploadImg.vue";
import { Mixins, Component, Watch, Ref, InjectReactive } from "vue-property-decorator";
import RichText from "@/components/rich-text.vue";
import { Form } from "element-ui/types";
import loading from "@/mixins/loading";
import { IHomeInstance } from "@/types/global";

import { appUpdateSave, getAppUpdateById } from "@/api/appUpdate";

@Component({
    components: { UploadImg, RichText }
})
export default class appUpdateEdit extends Mixins(loading) {
  @InjectReactive() HomeInstance !: IHomeInstance;

    @Ref("validateForm") validateForm: Form;

    formData = {
        versionNumber: "",
        imgUrl: [],
        updateDescription: "",
        updateContent: "",
        status: false
    };

    formRules = {
        versionNumber: [{ required: true, message: "请输入版本号", trigger: "blur" }],
        imgUrl: [{ required: true, message: "请上传图片", trigger: "blur" }],
        updateDescription: [{ required: true, message: "请输入更新说明", trigger: "blur" }],
        updateContent: [{ required: true, message: "请输入更新内容", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "change" }]
    }

    mounted() {
        if (this.$route.query.id) {
            this.getData();
        }
    }

    getData() {
        const id = this.$route.query.id;
        if (id) {
            this.showLoading();
            getAppUpdateById({ id }).then((res) => {
                const { status } = res.data;
                const _status = Boolean(status);
                Object.assign(this.formData, { ...res.data, status: _status });
            }).finally(() => {
                this.hideLoading();
            });
        }
    }

    dialogClose() {
        this.validateForm?.resetFields();
        this.HomeInstance.tabRemove("appUpdateEdit");
    }

    saveAppUpdate() {
        this.validateForm?.validate((valid) => {
            if (valid) {
                const params = {
                    ...this.formData,
                    id: this.$route.query.id,
                    imgUrl: this.formData.imgUrl[0].url,
                    status:Number(this.formData.status),
                }
                this.showLoading();
                appUpdateSave(params).then((res) => {
                    this.$message.success("保存成功");
                    this.dialogClose();
                }).finally(() => {
                    this.hideLoading();
                });
            }
            else {
                return false;
            }
        })
    }

}
