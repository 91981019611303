
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { adminList, adminDelete } from "@/api/user";
import { allRole } from "@/api/admin";
import { Role } from "@/types/api/admin";
import { User } from "@/types/api/user";
import copy from "@/utils/deepCopy";
// 混入
import Loading from "@/mixins/loading";
import TablePagination from "@/mixins/tablePagination";

import AuthorityInfo from "./authorityInfo.vue";

@Component({
  components: { AuthorityInfo },
})
export default class Header extends Mixins(Loading, TablePagination) {
  @Prop([String]) private keyword!: string;
  @Prop([String]) private phone!: string;
  tableData: Array<User> = [];

  dialogVisible: boolean = false;
  id: number = 0;

  /**
   * methods
   */
  getData(resetPage: boolean = false) {
    this.showLoading();
    if (resetPage) {
      this.currentPage = 1;
    }
    adminList({
      keyword: this.keyword,
      phone: this.phone,
      pageSize: this.pageSize,
      pageNum: this.currentPage,
    }).then((res: any) => {
      res.data.list.forEach((e: any) => {
        e.status = e.status == 0 ? false : true;
      });
      this.tableData = res.data.list;
      this.total = res.data.total;
      this.hideLoading();
    });
  }
  // 删除
  handleDelete(row: User) {
    this.$confirm(`是否要删除该用户（编号${row.id}） ?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      adminDelete({
        id: row.id,
      }).then((res: any) => {
        this.getData();
        this.$message.success("删除成功");
      });
    });
  }

  authorityInfo(id: number) {
    this.id = id;
    this.dialogVisible = true;
  }
}
