
import { Mixins, Component, Ref, Watch } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import { Form } from "element-ui/types";
import ETable from '@/components/ETable.vue';
import { getMerchantList } from "@/api/prescriptionDb";
import UploadExcel from "@/components/uploadExcel.vue";
import config from "@/config/default";

@Component({
  components: { ETable, UploadExcel }
})
export default class ProductImport extends Mixins(tablePagination, loading, Resize) {
  @Ref("validateForm") formRef: Form;
  @Ref("uploadExcelRef") uploadExcelRef: UploadExcel;

  formData = {
    merchantId: "",
    file: []
  };

  rules = {
    merchantId: [
      { required: true, message: "请选择品牌方", trigger: "change" },
    ],
    file: [
      { required: true, message: "请上传文件", trigger: "change" },
    ]
  };

  merchantOptions = [];

  columns = [
    { label: "ID", prop: "id", width:"100", align: "center" },
    { label: "产品信息", prop: "name", align: "center", },
    { label: "操作人", prop: "modifiedBy", align: "center" },
  ]

  tableData = [];

  uploadUrl = "";

  mounted() {
    this.windowResize();
    this.getMerchantList();
  }

  getMerchantList() {
    getMerchantList({ pageNum: 1, pageSize: 100 }).then((res) => {
      this.merchantOptions = res.data.list;
      this.merchantOptions.unshift({
        id: 0,
        name: "养身帮",
      });
    });
  }

  importProduct() {
    this.formRef?.validate().then((valid) => {
      this.uploadExcelRef?.uploadExcelRef?.submit();
    })
  }

  syncProductList(e) {
    this.tableData = e;
  }

  @Watch("formData.merchantId")
  merchantIdChange(val) {
    this.uploadUrl = `${config.baseUrl}/prescription/presCategory/import/${val}`
  }
}
