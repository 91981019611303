
import { Watch, Component, Vue, Ref, PropSync, Prop, Mixins } from 'vue-property-decorator';
import loading from "@/mixins/loading";
import { getDetectionResultById, createNurseSymptomRelations, getIntelligentList, createOrUpdateIntelligent } from "@/api/symptomManage";
import { DiagnosesTypeEnum } from "@/enum/common.enum";

import { cloneDeep } from "lodash";

@Component
export default class relateDetection extends Mixins(loading) {
    @PropSync("visible") showTransfer !: boolean;
    @Prop({ type: Array, default: () => [] }) stashList !: Array<any>;
    @Prop({ default: true }) clearStashListFlag !: boolean;
    @Prop({ default: "" }) nurseId !: string;
    @Ref("transferRef") transferRef: any;

    diagnosesTypeOptions = [
        { label: "智能脉诊", value: "10003" },
        { label: "智能舌诊", value: "10001" },
        { label: "体质辨识", value: "10004" },
        { label: "肺功能评估", value: "10005" },
        { label: "智能辨证", value: "10007" },
    ]

    stashResultList = [];
    resultList = [];
    diagnosesId = "10003";

    relationList = [];
    mounted() {
        this.getDetectionResultList();
    }
    getDetectionResultList() {
        this.showLoading();
        getDetectionResultById(this.diagnosesId).then((res) => {
            this.resultList = res.data;
            console.log(this.resultList)
        }).finally(() => {
            this.hideLoading();
        });
    }

    getIntelligentList() {
        getIntelligentList().then((res) => {
            this.resultList = res.data?.map(el => {
                el.name = el.symptom
                return el;
            });
        })
    }

    handleClick(event) {
        const { name } = event;
        this.diagnosesId = name;
        // @ts-ignore
        if (this.diagnosesId == DiagnosesTypeEnum.Dialectic) {
            return this.getIntelligentList()
        }
        this.getDetectionResultList();
    }
    closeTransfer() {
        this.transferRef?.clearQuery("left");
        this.transferRef?.clearQuery("right");
        this.relationList = [];
        if (this.clearStashListFlag) this.clearStashList();
        this.showTransfer = false;
    };

    clearStashList() {
        this.stashResultList = [];
    };

    confirmTransfer() {
        Promise.all([this.updateDialecticSymptom(), this.updatePresSymptom()]).then(() => {
            this.$message.success("保存成功");
            this.closeTransfer();
            // 更新列表
            this.$emit("syncList");
        })
    };

    updateDialecticSymptom() {
        const dialectStashList = this.stashResultList[4];
        const params = {
            params: dialectStashList?.map(el => {
                return {
                    diagnosesId: DiagnosesTypeEnum.Dialectic,
                    intelligentId: el,
                    symptomId: this.nurseId
                }
            }),
            symptomId: this.nurseId,
        }
        return createOrUpdateIntelligent(params)
    }

    updatePresSymptom() {
        const _stashResultList = cloneDeep(this.stashResultList)
        _stashResultList?.splice(4, 1);
        _stashResultList.forEach((item, index) => {
            if (!item.length) return;
            item.forEach(el => {
                this.relationList.push({
                    nurseId: this.nurseId,
                    diagnosesId: this.diagnosesTypeOptions[index].value,
                    symptomId: el
                })
            })
        });
        const params = {
            symptomId: this.nurseId,
            params: this.relationList,
        }
        return createNurseSymptomRelations(params)
    }

    @Watch("stashList", { deep: true })
    handleStashList(list) {
        this.stashResultList = [...list];
    }

    @Watch("showTransfer", { immediate: true })
    changeTypeValue() {
        if (this.showTransfer) {
            this.diagnosesId = "10003";
            this.getDetectionResultList();
        }
    }
}
