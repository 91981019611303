import { service } from '@/ajax/request'


export const deviceList = (params: any) => (
    service({
        url: '/QiaoDevice/query_paging',
        method: 'POST',
        params,
    })
)

export const queryService = (params) => {
    return service({
        url: '/QiaoDevice/query_service',
        method: 'POST',
        params,
    })
}



export const activateDevice = (params: any) => (
    service({
        url: '/QiaoDevice/activate/batch',
        method: 'POST',
        type: 'form',
        params
    })
)

export const settlement = (params: any) => {
    return service({
        url: '/QiaoDevice/settlement',
        method: 'POST',
        params
    })
}

export const renew = (params: any) => {
    return service({
        url: '/QiaoDevice/renew',
        method: 'POST',
        params
    })
}
export const getQiaoRenewList = (params: any) => {
    return service({
        url: '/qiao-renew-records/get-page',
        method: 'POST',
        params
    })
}

