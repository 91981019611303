
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    InjectReactive
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";
import { ElForm } from "element-ui/types/form";

import { updateNursePresBaseInfo } from "@/api/nurse";

import {
    getPresOne
} from "@/api/nurse";

import { IHomeInstance } from "@/types/global";

@Component({
    components: {
        UploadImg,
    },
    inject: ["HomeInstance"],
})
export default class PresAddHome extends Vue {
    @Ref("validateFormRef") validateFormRef!: ElForm;

    @InjectReactive() HomeInstance !: IHomeInstance;

    formData = {
        id: "",
        presType: 2,
        presName: "",
        usageFor: "",
        imgUrl: [],
        dosage: "",
        status: false,
        formula: "",
        effect: "",
    }

    rules = {
        presName: { required: true, message: "请输入处方名称", trigger: ['blur', 'change'] },
        usageFor: { required: true, message: "请输入适用人群", trigger: ['blur', 'change'] },
        imgUrl: { required: true, type: "array",  message: "请上传处方图", trigger: ['blur', 'change'] },
        formula: { required: true,  message: "请输入配方", trigger: ['blur', 'change'] },
        effect: { required: true,  message: "请输入功效", trigger: ['blur', 'change'] },
    }

    saveLoading = false;

    mounted() {
        this.formData.id = this.$route.query.id as string;
        this.getPresOne();
    }

    getPresOne() {
        if (!this.formData.id) return;
        getPresOne(this.formData.id).then((res) => {
            Object.assign(this.formData,{
                ...res.data,
                status: Boolean(res.data.status),
                imgUrl: [{
                    url: res.data.imgUrl
                }]
            });
        })
    }

    dialogClose() {
        this.HomeInstance.tabRemove("nurse-pres-add-home");
    }

dialogConfirm() {
    this.validateFormRef?.validate().then(() => {
        console.log("提交表单")
        const params = {
                ...this.formData,
                imgUrl: this.formData.imgUrl?.[0]?.url,
                status: Number(this.formData.status),
            }
            updateNursePresBaseInfo(params).then(() => {
                this.$message.success("操作成功");
                this.HomeInstance.tabRemove("nurse-pres-add-home");
            })
    })
}

}
