
import { Component, Ref, PropSync, Vue, Watch, Prop } from "vue-property-decorator";
import { Form } from "element-ui";

import { saveOrUpdateIntelligent } from "@/api/dialectic"

@Component({
    components: {
    }
})
export default class DialecticForm extends Vue {
    @Ref("formRef") validateFormRef: Form;
    @Prop({ default: [] }) symptomTypeOptions !: Array<any>
    @PropSync("form") modelForm !: any;
    @PropSync("visible") modelVisible !: boolean;

    formRule = {
        symptomType: { required: true, message: "请选择类型" },
        symptomName: { required: true, message: "请输入名称" },
        gender: { required: true, message: "请选择适用人群" },
    }
    
    get dialogTitle(){
        return this.modelForm.id ? "编辑" : "新增";
    }
    dialogCloseFun() {
        this.modelVisible = false;
        this.validateFormRef?.resetFields();
    }

    dialogSave() {
        this.validateFormRef?.validate().then(() => {
            console.log(this.modelForm)
            const params = { ...this.modelForm, symptomStatus: Number(this.modelForm.symptomStatus) }
            saveOrUpdateIntelligent(params).then((res) => {
                this.$message.success("操作成功");
                this.dialogCloseFun();
                this.$emit("syncList")
            })
        });
    }
}


