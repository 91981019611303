
import {
    Vue,
    Component,
    PropSync,
    Ref,
    Watch
} from "vue-property-decorator";

import { getNurseTenetList, getNurseSymptomList, saveNurseTenet } from "@/api/nurse";
import { Form } from "element-ui";

@Component({
    components: {}
})
export default class NurseTenetAdd extends Vue {
    @Ref("form") FormRef !: Form;
    @PropSync("form") dialogForm!: any;
    @PropSync("visible") dialogVisible !: boolean;

    rulesOptions = [];

    symptomOptions = [];

    saveLoading = false;
    
    formRules = {
        nurseName: { required: true, message: "请输入调养原则", trigger: ['blur', 'change'] },
        ruleDirection: { required: true, message: "请输入调养方向", trigger: ['blur', 'change'] }
    }

    get getDialogTitle() {
        return this.dialogForm.id ? "编辑" : "新增";
    }

    mounted(){
        // this.getNurseTenetList();
        // this.getNurseSymptomList();
    }

    @Watch("dialogVisible")
    getNurseSymptomList() {
        if (!this.dialogVisible) return;
        getNurseSymptomList({}).then((res) => {
            console.log("getNurseSymptomList", res)
            this.symptomOptions = res.data
        })
    }

    @Watch("dialogVisible")
    getNurseTenetList() {
        if (!this.dialogVisible) return;
        getNurseTenetList({ pageNum: 1, pageSize: 1000 }).then((res) => {
            console.log("getNurseSymptomList", this.dialogForm.id)
            this.rulesOptions = res.data.list?.filter((el) => {
                return  el.id != this.dialogForm.id;
            })
        })
    }

    handleClose() {
        this.dialogVisible = false;
        this.FormRef?.resetFields();
    }

    submit() {
        this.FormRef.validate().then(() => {
            const params = {
                id: this.dialogForm.id,
                nurseName: this.dialogForm.nurseName,
                ruleDirection: this.dialogForm.ruleDirection,
                status: Number(this.dialogForm.status),
                symptomList: this.dialogForm.symptomList?.map((el) => {
                    const info = this.symptomOptions?.find((s) => s.id == el);
                    return { id: info.id, name: info.name }
                }),

                ruleDesc: this.dialogForm.ruleDesc,

                preRules: this.dialogForm.preRules?.map((el) => {
                    const info = this.rulesOptions?.find((s) => s.id == el);
                    return { id: info.id, name: info.nurseName }
                }),

                mutexRules: this.dialogForm.mutexRules?.map((el) => {
                    const info = this.rulesOptions?.find((s) => s.id == el);
                    return { id: info.id, name: info.nurseName }
                }),

            }
            saveNurseTenet(params).then(() => {
                this.$message.success("操作成功");
                this.handleClose();
                this.$emit("syncList")
            })
        })
    }


}
